import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Stack,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Header.css";
import useScrollingUp from "../../../hooks/useScrollingUp";
import { LogoLight, fm_1, fm_2, fm_3 } from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { BUCKET_DOMAIN, STATUS } from "../../../helpers/Helper";
import { getFeatures } from "../../../features/car/featuredSlice";
import { toast } from "react-toastify";
import Loader from "../../../common/loader/Loader";

function Header() {
  const dispatch = useDispatch();

  const { features, status, success, message } = useSelector(
    (state) => state.features
  );

  const [menuOpen, setMenuOpen] = useState(false);
  const [activeModel, setActiveModel] = useState(0);
  const [loading, setLoading] = useState(false);
  const [allFeaturedCars, setAllFeaturedCars] = useState([]);

  useEffect(() => {
    if (status === STATUS.IDLE) {
      setLoading(true);
      dispatch(getFeatures());
    } else if (status === STATUS.SUCCEEDED) {
      setLoading(false);
      if (success) {
        setAllFeaturedCars(features);
      } else {
        toast.error(message);
      }
    } else if (status === STATUS.FAILED) {
      setLoading(false);
      toast.error(message);
    }
  }, [status, success, message, features, dispatch]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrolledUp = useScrollingUp();

  // const handleClick = (e) => {
  //   if (e.target.tagName === "a") {
  //     setMenuOpen(false);
  //   }
  // };

  const handleClick = (event) => {
    const anchor = event.target.closest("a");
    if (anchor) {
      // const href = anchor.getAttribute("href") || anchor.getAttribute("to");
      setMenuOpen(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <header className={menuOpen ? "header menuOpened" : "header"}>
        <Navbar
          className={scrolledUp ? "header_main scrolled" : "header_main"}
          onClick={handleClick}
        >
          <Container className="justify-content-between">
            <Button
              onClick={toggleMenu}
              variant="default"
              className={menuOpen ? "menu-toggle active" : "menu-toggle"}
            >
              <span className="menu-bar" />
              <span className="menu-bar" />
              <span className="menu-bar" />
              MENU
            </Button>

            <Navbar.Brand className="site-brand me-0">
              <Link to="/">
                {/* <img className="logoDark" src={LogoDark} width="100" alt="" /> */}
                <img className="logoLight" src={LogoLight} width="100" alt="" />
              </Link>
            </Navbar.Brand>

            <Nav className="nav-right nav_bar_menu">
              <Nav.Item className="nav-item">
                <Link className="nav-link" to="/support">
                  SUPPORT
                </Link>
              </Nav.Item>
            </Nav>
          </Container>
          <div className="drawer_menu">
            <Container>
              <Row>
                <Col md={4}>
                  <h4 className="drawer_head">Menu</h4>
                  <Nav className="flex-column nav_menu">
                    <Nav.Item>
                      <Link className="nav-link" to="/about-us">
                        ABOUT US
                      </Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Link to="/news-and-stories" className="nav-link">
                        NEWS
                      </Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Link to="/dealer-location" className="nav-link">
                        Dealer Location
                      </Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Link className="nav-link" to="/contact-us">
                        CONTACT US
                      </Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col md={3}>
                  <h4 className="drawer_head">Models</h4>
                  <Nav className="flex-column nav_menu ">
                    {allFeaturedCars.map((item, key) => {
                      return (
                        <Nav.Item key={key}>
                          <Link
                            className="nav-link"
                            to={`/details/${item.slug}`}
                            onMouseEnter={() => {
                              setActiveModel(key);
                            }}
                          >
                            {item.name}
                          </Link>
                        </Nav.Item>
                      );
                    })}
                  </Nav>
                </Col>
                <Col md={5}>
                  {allFeaturedCars.map((item, key) => {
                    return (
                      <div
                        className="model_item text-center"
                        style={{
                          display: activeModel === key ? "block" : "none",
                        }}
                        key={key}
                      >
                        <div
                          className={
                            activeModel === key ? "nmi animated" : "nmi"
                          }
                        >
                          <img
                            src={BUCKET_DOMAIN + item.primary_picture}
                            className="img-fluid"
                            alt={item.slug}
                          />
                        </div>
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="justify-content-center mt-3"
                        >
                          <Link
                            to={`/pre-order/${item.slug}`}
                            className="btn btn_primary"
                          >
                            Pre-Order
                          </Link>
                          <Link
                            to={`/schedule-a-test-drive/${item.slug}`}
                            className="btn btn_outline outline_light"
                          >
                            Schedule A Test Drive
                          </Link>
                        </Stack>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Container>
          </div>
        </Navbar>
      </header>

      <div className="floating_menu">
        <Link to="/support">
          <div className="floating_menu_item">
            <div className="fm_icon">
              <img src={fm_1} height="25px" alt="" />
            </div>
            <div className="fm_label">
              <h6 className="mb-0">Find Support</h6>
            </div>
          </div>
        </Link>
        <Link to="/dealer-location">
          <div className="floating_menu_item">
            <div className="fm_icon">
              <img src={fm_2} height="25px" alt="" />
            </div>
            <div className="fm_label">
              <h6 className="mb-0">Find Dealer</h6>
            </div>
          </div>
        </Link>
        <Link to="/contact-us">
          <div className="floating_menu_item">
            <div className="fm_icon">
              <img src={fm_3} height="25px" alt="" />
            </div>
            <div className="fm_label">
              <h6 className="mb-0">Contact Us</h6>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}

export default Header;
