import React, { useRef } from "react";
import { Fade } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "./Page.css";
import {
  // age_tech,
  banner_two,
  driving_comfort,
  about_us_lg,
  design_technology,
} from "../assets/images";

function Sustainability() {
  const sectionRef = useRef(null);
  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Fade duration="2000" triggerOnce="true">
        <div className="page_banner">
          <img src={banner_two} className="img-fluid" alt="" />

          <div className="pb_caption">
            <Container>
              <Row>
                <Col md={5}>
                  <h2 className="pb_title">Sustainability</h2>
                  <div className="pb_text">
                    Explore the Endurance Motors vehicle line-up with its
                    dynamic yet elegant design and cutting-edge technology.
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <span onClick={scrollToSection} className="scroll_bottom">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
      </Fade>
      <section className="section_alt" ref={sectionRef}>
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <Row className="align-items-center">
              <Col md={6} className="alt_img">
                <img src={about_us_lg} className="img-fluid" alt="" />
              </Col>
              <Col md={6} className="alt_text">
                <div className="heading">
                  <h2 className="title title_lg">
                    Commitment to Eco-Friendly Manufacturing
                  </h2>
                </div>
                <p>
                  At Endurance Motors, sustainability starts at the
                  manufacturing stage. We are dedicated to reducing our
                  environmental footprint by implementing eco-friendly practices
                  across our production facilities. This includes using
                  renewable energy sources, minimizing waste, and recycling
                  materials wherever possible. Our advanced manufacturing
                  processes ensure that every vehicle is built with a focus on
                  sustainability, without compromising on quality or
                  performance.
                </p>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
      <section className="section_alt">
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <Row className="align-items-center">
              <Col md={{ span: 6, order: 2 }} className="alt_img">
                <img src={driving_comfort} className="img-fluid" alt="" />
              </Col>
              <Col md={{ span: 6, order: 1 }} className="alt_text">
                <div className="heading">
                  <h2 className="title title_lg">
                    Innovative Green Technologies
                  </h2>
                </div>
                <p>
                  We continuously invest in and develop innovative green
                  technologies to make our vehicles more environmentally
                  friendly. Our lineup features electric and hybrid models that
                  significantly reduce carbon emissions and improve fuel
                  efficiency. Additionally, our cars incorporate advanced
                  energy-saving features such as regenerative braking, which
                  captures and reuses energy during deceleration. By integrating
                  these cutting-edge technologies, we strive to provide
                  eco-conscious driving solutions that benefit both our
                  customers and the planet.
                </p>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
      <section className="section_alt" style={{ marginBottom: "4rem" }}>
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <Row className="align-items-center">
              <Col md={6} className="alt_img">
                <img src={design_technology} className="img-fluid" alt="" />
              </Col>
              <Col md={6} className="alt_text">
                <div className="heading">
                  <h2 className="title title_lg">
                    Sustainable Materials and Design
                  </h2>
                </div>
                <p>
                  Sustainability is at the core of our design philosophy. We
                  prioritize the use of sustainable materials in our vehicles,
                  including recycled plastics, natural fibers, and eco-friendly
                  fabrics. Our commitment to green design extends to the entire
                  lifecycle of our cars, from production to end-of-life
                  recycling. By choosing Endurance Motors, you are not only
                  driving a stylish and innovative vehicle but also supporting a
                  brand that is dedicated to protecting the environment for
                  future generations.
                </p>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
    </>
  );
}

export default Sustainability;
