import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Header from "./pages/layout/header/Header";
import Footer from "./pages/layout/footer/Footer";
import HomePage from "./pages/home/HomePage";
import CarDetails from "./pages/cardetails/CarDetails";
import PreOrder from "./pages/preorder/PreOrder";
import TestDrive from "./pages/testdrive/TestDrive";
import News from "./pages/news/News";
import SingleNews from "./pages/news/SingleNews";
import Design from "./pages/Design";
import Technology from "./pages/Technology";
import Sustainability from "./pages/Sustainability";
import Contact from "./pages/Contact";
import Support from "./pages/Support";
import AboutUs from "./pages/AboutUs";
import DealerLocation from "./pages/DealerLocation";
import { ToastContainer } from "react-toastify";
import PrivacyPolicy from "./agreement/PrivacyPolicy";
import TermsAndCondition from "./agreement/TermsAndCondition";

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [location]);
  return null;
}

function App() {
  return (
    <ParallaxProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/details/:slug" element={<CarDetails />} />
          <Route path="/pre-order/:slug" element={<PreOrder />} />
          <Route path="/schedule-a-test-drive/:slug" element={<TestDrive />} />
          <Route path="/news-and-stories" element={<News />} />
          <Route path="/news-and-stories/:slug" element={<SingleNews />} />
          <Route path="/design" element={<Design />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/support" element={<Support />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/dealer-location" element={<DealerLocation />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          {/* <Route path="*" element={<NotFound/>}/> */}
        </Routes>
        <Footer />
      </Router>
    </ParallaxProvider>
  );
}

export default App;
