import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import "./News.css";
import { useParams } from "react-router-dom";
import { ERROR } from "../../helpers/MessageHelper";
import { toast } from "react-toastify";
import axios from "../../config/axios.config";
import Loader from "../../common/loader/Loader";
import moment from "moment";
import { BUCKET_DOMAIN, DATE_FORMAT } from "../../helpers/Helper";

function SingleNews() {
  const { slug } = useParams();

  const [loading, setLoading] = useState(false);

  const [newsDetails, setNewsDetails] = useState({});

  useEffect(() => {
    getNewsdetails();
    // eslint-disable-next-line
  }, [slug]);

  const getNewsdetails = () => {
    setLoading(true);
    axios
      .get(`/blog-post/${slug}`)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setNewsDetails(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  return (
    <>
      {loading && <Loader />}

      <div className="news_banner">
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <Fade duration="2000" triggerOnce="true">
                <div className="news_header">
                  <h2 className="single_news_title">{newsDetails.title}</h2>
                  <div className="news_meta">
                    <div className="meta_item">Endurance Admin</div>
                    <div className="meta_item">
                      {moment(newsDetails.createdAt).format(DATE_FORMAT.NEWS)}
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade duration="2000" triggerOnce="true">
                <div className="news_hero">
                  <img
                    src={BUCKET_DOMAIN + newsDetails.featured_image}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </Fade>
              <Fade duration="2000" triggerOnce="true">
                <div
                  className="news_text"
                  dangerouslySetInnerHTML={{
                    __html: newsDetails.body,
                  }}
                />
              </Fade>
              <Button variant="default">
                <FontAwesomeIcon icon={faShareNodes} /> Share Article
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SingleNews;
