import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faFacebookF,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import { useDispatch, useSelector } from "react-redux";
import { STATUS } from "../../../helpers/Helper";
import { toast } from "react-toastify";
import axios from "../../../config/axios.config";
import { ERROR } from "../../../helpers/MessageHelper";

const SOCIAL_MEDIA_ICONS = {
  facebook: faFacebookF,
  twittter: faXTwitter,
  instagram: faInstagram,
};

function Footer() {
  const dispatch = useDispatch();

  const [footerInnovation, setFooterInnovation] = useState({});
  const [footerSupport, setFooterSupport] = useState({});
  const [footerMotors, setFooterMotors] = useState({});
  const [footerPolicy, setFooterPolicy] = useState({});
  const [footerSocialMedia, setFooterSocialMedia] = useState({});

  const { features, status, success, message } = useSelector(
    (state) => state.features
  );

  const [allFeaturedCars, setAllFeaturedCars] = useState([]);

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (status === STATUS.SUCCEEDED) {
      if (success) {
        setAllFeaturedCars(features);
      } else {
        toast.error(message);
      }
    } else if (status === STATUS.FAILED) {
      toast.error(message);
    }
  }, [status, success, message, features, dispatch]);

  useEffect(() => {
    getAllWidget();
  }, []);

  const getAllWidget = () => {
    axios
      .post("/widget", {
        name: "INNOVATION & EXCELLENCE",
        for: "Endurance Motors",
      })
      .then((res) => {
        setFooterInnovation(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error(ERROR);
      });

    axios
      .post("/widget", {
        name: "SERVICE & SUPPORT",
        for: "Endurance Motors",
      })
      .then((res) => {
        setFooterSupport(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error(ERROR);
      });

    axios
      .post("/widget", {
        name: "ENDURANCE MOTORS",
        for: "Endurance Motors",
      })
      .then((res) => {
        setFooterMotors(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error(ERROR);
      });

    axios
      .post("/widget", {
        name: "Policy",
        for: "Endurance Motors",
      })
      .then((res) => {
        setFooterPolicy(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error(ERROR);
      });
    axios
      .post("/widget", {
        name: "Social Media Motors",
        for: "Endurance Motors",
      })
      .then((res) => {
        setFooterSocialMedia(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error(ERROR);
      });
  };

  const getDomainName = (url) => {
    const hostname = new URL(url).hostname;
    const domainParts = hostname.replace("www.", "").split(".");
    return domainParts[0];
  };

  return (
    <Fade duration="2000" triggerOnce="true">
      <footer className="footer pt-5">
        <div className="backToTop" onClick={scrollTop}>
          Back To Top
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-sm-6 footer_item">
              <h2 className="footer_title">MODELS</h2>
              <ul className="menu_links list-unstyled mb-0">
                {allFeaturedCars.map((item, key) => {
                  return (
                    <li className="mb-2" key={key}>
                      <Link to={`/details/${item.slug}`}> {item.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="col-md-4 col-sm-6 footer_item">
              <h2 className="footer_title">{footerInnovation.name}</h2>
              <ul className="menu_links list-unstyled mb-0">
                {footerInnovation.details?.map((item, key) => {
                  return (
                    <li key={key} className="mb-2">
                      <Link to={item.value}>{item.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="col-md-3 col-sm-6 footer_item">
              <h2 className="footer_title">{footerSupport.name}</h2>
              <ul className="menu_links list-unstyled mb-0">
                {footerSupport.details?.map((item, key) => {
                  return (
                    <li key={key} className="mb-2">
                      <Link to={item.value}>{item.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="col-md-3 col-sm-6 footer_item">
              <h2 className="footer_title">{footerMotors.name}</h2>
              <ul className="menu_links list-unstyled mb-0">
                {footerMotors.details?.map((item, key) => {
                  return (
                    <li key={key} className="mb-2">
                      <Link
                        to={item.value}
                        target={item.value.includes("http") ? "_blank" : ""}
                      >
                        {item.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          <div className="copyright">
            <div className="row align-items-center">
              <div className="col-md-4 copyright_text">
                <p className="mb-0">
                  &copy;
                  {new Date().getFullYear()} Endurance Motors. All Rights
                  Reserved.
                </p>
              </div>
              <div className="col-md-4">
                <ul className="privacy_menu list-inline mb-0">
                  {footerPolicy.details?.map((item, key) => {
                    return (
                      <li key={key} className="list-inline-item">
                        <Link to={item.value}>{item.name}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="social_links list-inline mb-0">
                  {footerSocialMedia.details?.map((item, key) => {
                    return (
                      <li key={key} className="list-inline-item">
                        <Link to={item.value}>
                          {/* {console.log(getDomainName(item.value))} */}
                          <FontAwesomeIcon
                            icon={SOCIAL_MEDIA_ICONS[getDomainName(item.value)]}
                          />
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fade>
  );
}

export default Footer;
