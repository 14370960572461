import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Swiper from "swiper";
import { Link } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";
import { MouseContainer, ChasingElement } from "react-mouse-image-move";
import { Fade } from "react-awesome-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ParallaxBanner } from "react-scroll-parallax";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "./HomePage.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import {
  banner_one,
  banner_two,
  banner_three,
  driving_comfort,
  // automotive_elegance,
  sustainability,
  design,
  technology,
  safety,
  service,
  about_us_lg,
} from "../../assets/images";
import tiggoVideo from "./../../assets/videos/toggo8pro.mp4";
import Loader from "../../common/loader/Loader";
import { BUCKET_DOMAIN, DATE_FORMAT, STATUS } from "../../helpers/Helper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ERROR } from "../../helpers/MessageHelper";
import axios from "../../config/axios.config";
import moment from "moment";

function HomePage() {
  const sectionRef = useRef(null);
  const dispatch = useDispatch();

  const { features, status, success, message } = useSelector(
    (state) => state.features
  );

  const [loading, setLoading] = useState(false);
  const [allFeaturedCars, setAllFeaturedCars] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [allNews, setAllNews] = useState([]);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const bannerSwiper = new Swiper(".banner", {
      loop: true,
      speed: 800,
      slidesPerView: 1,
      modules: [Pagination, Autoplay],
      pagination: {
        el: ".pagination_banner",
        clickable: true,
      },
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
    });

    const swiperOverview = new Swiper(".swiperOverview", {
      loop: true,
      speed: 800,
      spaceBetween: 20,
      grabCursor: true,
      modules: [Navigation, Scrollbar],
      slidesPerView: 4,
      scrollbar: {
        el: ".overview-slider-scrollbar",
      },
      navigation: {
        nextEl: ".overview-button-next",
        prevEl: ".overview-button-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        481: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 4,
        },
      },
    });

    getAllNews();
    return () => {
      bannerSwiper.destroy();
      swiperOverview.destroy();
    };
  }, []);

  useEffect(() => {
    if (status === STATUS.SUCCEEDED) {
      setLoading(false);
      if (success) {
        setAllFeaturedCars(features);
      } else {
        toast.error(message);
      }
    } else if (status === STATUS.FAILED) {
      setLoading(false);
      toast.error(message);
    }
  }, [status, success, message, features, dispatch]);

  const getAllNews = () => {
    setLoading(true);

    axios
      .post("/blog-posts", { start: 0, length: 10, for: "Endurance Motors" })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setAllNews(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const exhibitionStyles = {
    mouseContainer: {},
    chasingElement: {
      width: "100%",
    },
  };
  // const handleItemHover = (index) => {
  //     setActiveItem(index);
  // };

  const SLIDER_CONTENT = [
    {
      title: "THE AMERICAN LUXURY LINE OF ENDURANCE CARS",
      desc: "Elevate your driving experience to new heights with Endurance's latest endeavor",
      banner: banner_one,
    },
    {
      title: "THE EPITOME OF AMERICAN LUXURY BY ENDURANCE CARS",
      desc: "Ascend to Unprecedented Comfort-Endurance's Latest Innovation Redefines Driving Excellence",
      banner: banner_two,
    },
    {
      title: "DISCOVER OPULENCE ON WHEELS - AMERICAN LUXURY",
      desc: "Reach New Peaks of Luxury-Endurance's Cutting-Edge Advancements Transform Your Journey",
      banner: banner_three,
    },
  ];

  return (
    <>
      {loading && <Loader />}

      {/* BANNER */}
      <Fade duration="2000" triggerOnce="true">
        <div className="banner swiper">
          <div className="swiper-wrapper">
            {SLIDER_CONTENT.map((item, key) => {
              return (
                <div className="swiper-slide" key={key}>
                  <MouseContainer
                    options={{
                      effectType: "movement", // or 'movement'
                      max: 10,
                      easing: "cubic-bezier(.03,.98,.52,.99)",
                    }}
                    styles={exhibitionStyles.mouseContainer}
                    className="banner_caption"
                    chasingElement={
                      <ChasingElement
                        styles={exhibitionStyles.chasingElement}
                        className="container"
                      >
                        <div className="caption_content">
                          <h2 className="caption_title">{item.title}</h2>
                          <p className="caption_body">{item.desc}</p>
                          <Link to={`/about-us`} className="btn btn_outline">
                            Know More
                          </Link>
                        </div>
                      </ChasingElement>
                    }
                  />
                  <img
                    src={item.banner}
                    className="img-fluid w-100"
                    alt={item.title}
                  />
                </div>
              );
            })}
          </div>
          <div className="pagination_light pagination_banner has_progress"></div>
          <span onClick={scrollToSection} className="scroll_bottom">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
      </Fade>

      {/* ABOUT */}
      <section ref={sectionRef} className="about_us">
        <Fade duration="2000" triggerOnce="true">
          <Container className="position-relative">
            <Row className="align-items-center">
              <Col md={5}>
                <div className="about_text">
                  <div className="heading">
                    <h2 className="title title_lg">About Endurance</h2>
                  </div>
                  <p className="sub_title mb-3">
                    We believe a sustainable future is only possible when
                    everyone can enjoy access to smart, safe, and
                    environmentally friendly EVs. Through global partnerships
                    with trusted suppliers and organizations, and unprecedented
                    investment in R&D, Endurance is ready to lead the EV
                    revolution.
                  </p>
                  <Link to="/about-us" className="btn btn_outline outline_dark">
                    Know More
                  </Link>
                </div>
              </Col>
              <Col md={7}>
                <div className="about_img">
                  <img src={about_us_lg} className="img-fluid" alt="About" />
                </div>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>

      {/* TAB */}
      <section className="models_area">
        <Fade duration="2000" triggerOnce="true">
          <div className="container">
            <div className="heading text-center">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="sub_title">OUR MODELS</div>
                  <h2 className="title title_lg">Explore Our Models</h2>
                </div>
              </div>
            </div>

            <Tabs
              id="controlled-tab-example"
              activeKey={currentTab}
              onSelect={(k) => setCurrentTab(k)}
              className="mb-3 nav_tab_models"
            >
              {allFeaturedCars.map((item, key) => {
                return (
                  <Tab eventKey={key} title={item.name} key={key}>
                    <div
                      className="model_wrap"
                      style={{
                        background: `url(${
                          BUCKET_DOMAIN + item?.primary_picture
                        })`,
                        backgroundSize: "cover",
                      }}
                    >
                      <Row className="model_row align-items-end">
                        <Col md={6}>
                          <div className="model_action">
                            <div className="model_name">{item?.name}</div>
                            <div className="stack">
                              <Link
                                to={`/pre-order/${item.slug}`}
                                className="btn btn_primary"
                              >
                                Pre-Order
                              </Link>
                              <Link
                                to={`/schedule-a-test-drive/${item.slug}`}
                                className="btn btn_outline outline_light"
                              >
                                Schedule a Test Drive
                              </Link>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="model_desc">
                            <Row className="model_info mx-0">
                              {item?.specifications
                                .slice(0, 3)
                                .map((spe, s_key) => {
                                  return (
                                    <Col
                                      className="model_info_item"
                                      xs={4}
                                      key={s_key}
                                    >
                                      <div className="model_info_title">
                                        {spe.value}
                                      </div>
                                      <div className="model_info_legend">
                                        {spe.label}
                                      </div>
                                    </Col>
                                  );
                                })}
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Tab>
                );
              })}
            </Tabs>
          </div>
        </Fade>
      </section>

      <Fade duration="2000" triggerOnce="true">
        <div className="automotive_elegance video_bg_cont">
          <div className="video_bg">
            <video
              width="100%"
              height="800px"
              loop={true}
              autoPlay="autoplay"
              muted
            >
              <source src={tiggoVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="container position-relative">
            <div className="row justify-content-end">
              <div className="col-md-5">
                <div className="ae_text">
                  <div className="heading">
                    <h2 className="title title_lg mb-5">
                      Sleek designs redefine automotive elegance
                    </h2>
                  </div>
                  <Link to="/design" className="btn btn_outline outline_light">
                    Explore Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>

      <section className="overview">
        <Fade duration="2000" triggerOnce="true">
          <div className="container">
            <div className="swiperOverview swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <Link to="/sustainability">
                    <div className="you-slide-item">
                      <div className="you-slide-image">
                        <img
                          src={sustainability}
                          className="img-fluid w-100"
                          alt=""
                        />
                      </div>
                      <div className="sc_item_text">
                        <h5>Sustainability</h5>
                        <p>
                          we are committed to sustainability by promoting
                          eco-friendly vehicles and implementing green practices
                          across our operations to reduce our environmental
                          impact.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="swiper-slide">
                  <Link to="/design">
                    <div className="you-slide-item">
                      <div className="you-slide-image">
                        <img src={design} className="img-fluid w-100" alt="" />
                      </div>
                      <div className="sc_item_text">
                        <h5>Design</h5>
                        <p>
                          our website design prioritizes user experience with a
                          sleek, intuitive interface that makes it easy to find
                          and compare vehicles, browse services, and access
                          information seamlessly.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="swiper-slide">
                  <Link to="/technology">
                    <div className="you-slide-item">
                      <div className="you-slide-image">
                        <img
                          src={technology}
                          className="img-fluid w-100"
                          alt=""
                        />
                      </div>
                      <div className="sc_item_text">
                        <h5>Technology</h5>
                        <p>
                          We leverage cutting-edge technology to provide
                          real-time inventory updates, advanced search
                          functionalities, and personalized recommendations,
                          ensuring a smooth and efficient car buying experience.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="swiper-slide">
                  <Link to="/technology">
                    <div className="you-slide-item">
                      <div className="you-slide-image">
                        <img src={safety} className="img-fluid w-100" alt="" />
                      </div>
                      <div className="sc_item_text">
                        <h5>Safety</h5>
                        <p>
                          Your safety is our priority; we implement robust
                          security measures to protect your personal information
                          and ensure a secure browsing and transaction
                          environment.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="swiper-slide">
                  <Link to="/support">
                    <div className="you-slide-item">
                      <div className="you-slide-image">
                        <img src={service} className="img-fluid w-100" alt="" />
                      </div>
                      <div className="sc_item_text">
                        <h5>Service</h5>
                        <p>
                          We offer exceptional service, providing comprehensive
                          resources, expert advice, and seamless transactions to
                          help you find the perfect vehicle and meet all your
                          automotive needs with ease.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <Row className="justify-content-between">
                <Col sm={4}>
                  <div className="swiper-scrollbar overview-slider-scrollbar"></div>
                </Col>
                <Col sm={2}>
                  <div className="overview-nav-button">
                    <div className="swiper-button-prev overview-button-prev"></div>
                    <div className="swiper-button-next overview-button-next"></div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Fade>
      </section>

      <Fade duration="2000" triggerOnce="true">
        <ParallaxBanner
          layers={[{ image: driving_comfort, speed: -10 }]}
          className="driving_comfort"
        >
          <div className="container position-relative">
            <div className="row">
              <div className="col-md-5">
                <div className="dc_text">
                  <div className="heading">
                    <h2 className="title title_lg mb-5">
                      Where Luxury Meets Driving Comfort
                    </h2>
                  </div>
                  <Link
                    to="/technology"
                    className="btn btn_outline outline_dark"
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </ParallaxBanner>
      </Fade>

      {/* NEWS */}
      <section className="news">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="heading text-center">
                <h2 className="title title_lg mb-2">News And Stories</h2>
                <p className="sub_title mb-4">
                  Explore the Endurance Motors vehicle line-up with its dynamic
                  yet elegant design and cutting-edge technology.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            {allNews.map((item, key) => {
              return (
                <Col key={key} md={6}>
                  <Fade duration="2000" triggerOnce="true">
                    <div className="news_card">
                      <img
                        src={BUCKET_DOMAIN + item.featured_image}
                        className="img-fluid mb-3"
                        alt=""
                      />
                      <div className="sub_title text-uppercase">
                        <small>
                          {moment(item.createdAt).format(DATE_FORMAT.NEWS)}
                        </small>
                      </div>
                      <h4 className="news_title mb-3">{item.title}</h4>
                      <Link
                        to={`/news-and-stories/${item.slug}`}
                        className="btn btn_outline outline_dark"
                      >
                        Know More
                      </Link>
                    </div>
                  </Fade>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default HomePage;
