export const BUCKET_DOMAIN =
  "https://endurance-devapi.webgen.me/public/uploads/"; // STAGING

// export const BUCKET_DOMAIN =
//   "https://prodapi.endurancemotors.us//public/uploads/"; // PROD

export const STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
};

export const DATE_FORMAT = {
  NEWS: "MMMM DD, YYYY", // "January 28, 2024"
};
