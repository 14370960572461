import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "./PreOrder.css";
import { Link, useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import validation from "../../helpers/validation";
import Loader from "../../common/loader/Loader";
import axios from "../../config/axios.config";
import { ERROR } from "../../helpers/MessageHelper";
import { toast } from "react-toastify";
import { BUCKET_DOMAIN } from "../../helpers/Helper";
import _ from "lodash";

function PreOrder() {
  const { slug } = useParams();

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    model: "",
    car_id: "",
    variant: "",
    color: "",
    country: "",
    state: "",
    city: "",
    dealer: "",
    address: "",
    zip_code: "",
    card_no: "",
    card_type: "",
    card_expiry: "",
    cvv: "",
    card_holder: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [step2Errors, setStep2Errors] = useState({});
  const [step3Errors, setStep3Errors] = useState({});
  const [allCountries, setAllCountries] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allCityes, setAllCityes] = useState([]);
  const [allDealers, setAllDealers] = useState([]);
  const [machineDetails, setMachineDetails] = useState({});
  const [allVarients, setAllVarients] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState({});

  useEffect(() => {
    getMachineDetails();
    // eslint-disable-next-line
  }, [slug]);

  const getMachineDetails = () => {
    setLoading(true);
    axios
      .get(`/car/${slug}`)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setMachineDetails(res.data.data);
          formData.model = res.data.data.name;
          formData.car_id = res.data.data.car_id;
          setFormData({ ...formData });
          getAllVarient(res.data.data.car_id);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const getAllVarient = (car_id) => {
    setLoading(true);

    axios
      .post("/car-variants", { car_id })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setAllVarients(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const getAllCountryes = () => {
    setLoading(true);

    axios
      .get("/countries")
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setAllCountries(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const getAllState = (country) => {
    setLoading(true);

    axios
      .post("/states", { country })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setAllStates(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const getAllCity = (country, state) => {
    setLoading(true);

    axios
      .post("/cities", { country, state })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setAllCityes(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const getAllDealers = (state, city) => {
    setLoading(true);

    axios
      .post("/dealers", { state, city })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setAllDealers(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const expriy_format = (value) => {
    const expdate = value;
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4);
    return expDateFormatter;
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleOnChange = (e, step = 1) => {
    e.persist();
    let { name, value } = e.target;

    if (name === "card_expiry") {
      const VALID_MONTHS = "Enter valid month";
      value = value.replace(/[^0-9/]/g, "");
      formData.card_expiry = value;
      setFormData({ ...formData });

      const data = value.split("/");
      let month = data[0] || 0;
      let year = data.length > 1 ? data[1] : 0;

      month = parseInt(month);
      year = parseInt(year);

      if (month >= 1 && month <= 12) {
        step3Errors.card_expiry = "";
      } else {
        step3Errors.card_expiry = VALID_MONTHS;
      }
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear() % 100;

      if (step3Errors.card_expiry !== VALID_MONTHS) {
        if (year >= currentYear) {
          step3Errors.card_expiry = "";
        } else {
          step3Errors.card_expiry = "Enter valid year";
        }
      }
      setStep3Errors({ ...step3Errors });

      return;
    }

    formData[name] = value;

    setFormData({ ...formData });

    const valid_obj = {
      value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };

    if (name === "variant") {
      if (value) {
        const variant = _.find(allVarients, (o) => {
          return o.variant_id === value;
        });
        setSelectedVariant(variant);
      }

      formData.color = "";
      setFormData({ ...formData });
    }

    if (name === "country") {
      formData.state = "";
      formData.city = "";
      setFormData({ ...formData });
      if (value) getAllState(value);
    }
    if (name === "state") {
      formData.city = "";
      setFormData({ ...formData });
      if (value) getAllCity(formData.country, value);
    }
    if (name === "city") {
      formData.dealer = "";
      setFormData({ ...formData });
      if (value) getAllDealers(formData.state, value);
    }

    switch (step) {
      case 1: // STEP 1
        validation(valid_obj).then((err) => {
          errors[name] = err;
          setErrors({ ...errors });
        });
        return;
      case 2: //  STEP 2
        validation(valid_obj).then((err) => {
          step2Errors[name] = err;
          setStep2Errors({ ...step2Errors });
        });
        return;
      case 3: //  STEP 3
        validation(valid_obj).then((err) => {
          step3Errors[name] = err;
          setStep3Errors({ ...step3Errors });
        });
        return;

      default:
        return;
    }
  };

  const handlePhoneChange = (value) => {
    formData.phone = value;
    setFormData({ ...formData });
  };

  const isValidForm = (errors) => {
    for (const [, value] of Object.entries(errors)) {
      if (value.length > 0) {
        return false;
      }
    }
    return true;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (isValidForm(step3Errors)) {
      setLoading(true);
      axios
        .post("/place-order", formData)
        .then((res) => {
          setLoading(false);
          if (res.data.status) {
            toast.success(res.data.message);
            window.history.back();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          toast.error(ERROR);
        });
    } else {
      toast.error("Enter valid details");
    }
  };

  return (
    <div className="pod_area">
      {loading && <Loader />}
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <Fade duration="2000" triggerOnce="true">
              <div className="pof_header text-center">
                <div className="heading">
                  <h2 className="title title_lg">Pre-Order/Order</h2>
                  <div className="sub_title">
                    Explore the Endurance Motors vehicle line-up with its
                    dynamic yet elegant design and cutting-edge technology.
                  </div>
                </div>
                <div className="steps d-flex">
                  <div
                    className={`stepItem ${currentStep > 1 ? "done" : ""} ${
                      currentStep === 1 ? "active" : ""
                    }`}
                  >
                    <span className="step_count">
                      {currentStep > 1 ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        "1"
                      )}
                    </span>
                    <div className="step_label">Step-1</div>
                  </div>
                  <div
                    className={`stepItem ${currentStep > 2 ? "done" : ""} ${
                      currentStep === 2 ? "active" : ""
                    }`}
                  >
                    <span className="step_count">
                      {currentStep > 2 ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        "2"
                      )}
                    </span>
                    <div className="step_label">Step-2</div>
                  </div>
                  <div
                    className={`stepItem ${currentStep > 3 ? "done" : ""} ${
                      currentStep === 3 ? "active" : ""
                    }`}
                  >
                    <span className="step_count">
                      {currentStep > 3 ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        "3"
                      )}
                    </span>
                    <div className="step_label">Step-3</div>
                  </div>
                </div>
              </div>
            </Fade>
          </Col>
        </Row>
        <Fade duration="2000" triggerOnce="true">
          <Row>
            <Col md={6}>
              <>
                {currentStep === 1 && (
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();

                      if (!formData.phone) {
                        toast.error("Enter valid phone number");
                        return;
                      }

                      if (isValidForm(errors)) {
                        if (allCountries.length === 0) {
                          getAllCountryes();
                        }
                        setCurrentStep(currentStep + 1);
                      } else {
                        toast.error("Enter valid details");
                      }
                    }}
                  >
                    <div className="pre_order_form">
                      <div className="pof_scrol mb-3">
                        <div className="pod_form_item">
                          <div className="pod_form_legend">
                            Customer Information
                          </div>
                          <FloatingLabel
                            className="mb-3"
                            label={
                              <span>
                                Name <span className="required">*</span>
                              </span>
                            }
                          >
                            <Form.Control
                              type="text"
                              placeholder="eg.john doe"
                              required
                              name="name"
                              value={formData.name}
                              validaterule={["required", "isName"]}
                              validatemsg={["Name is required"]}
                              onChange={handleOnChange}
                            />
                            <p className="invalid_input">{errors.name}</p>
                          </FloatingLabel>
                          <FloatingLabel
                            className="mb-3"
                            label={
                              <span>
                                Email <span className="required">*</span>
                              </span>
                            }
                          >
                            <Form.Control
                              type="email"
                              placeholder="name@example.com"
                              required
                              name="email"
                              value={formData.email}
                              validaterule={["required", "isEmail"]}
                              validatemsg={["Email id is required"]}
                              onChange={handleOnChange}
                            />
                            <p className="invalid_input">{errors.email}</p>
                          </FloatingLabel>
                          {/* <Row className="mx-0"> */}
                          {/* <Col sm={6} className="ps-0"> */}
                          <PhoneInput
                            className="mb-3"
                            country={"us"}
                            disableDropdown={true}
                            countryCodeEditable={false}
                            value={formData.phone}
                            onChange={handlePhoneChange}
                          />
                          <p className="invalid_input">{errors.phone}</p>

                          {/* </Col> */}
                          {/* <Col sm={6} className="pe-0">
                              <FloatingLabel
                                className="mb-3"
                                label={<span>OTP </span>}
                              >
                                <Form.Control
                                  type="password"
                                  placeholder="OTP"
                                />
                                <div className="send_otp">Send OTP</div>
                              </FloatingLabel>
                            </Col> */}
                          {/* </Row> */}
                        </div>
                        <div className="pod_form_item">
                          <div className="pod_form_legend">Car Details</div>
                          <FloatingLabel className="mb-3" label="Select model">
                            <Form.Control
                              name="model"
                              value={formData.model}
                              readOnly
                            />
                          </FloatingLabel>
                          <FloatingLabel
                            className="mb-3"
                            label="Select Your Variant"
                          >
                            <Form.Select
                              required
                              name="variant"
                              value={formData.variant}
                              validaterule={["required"]}
                              validatemsg={["Varient is required"]}
                              onChange={handleOnChange}
                            >
                              <option value="">Select variant</option>

                              {allVarients.map((item, key) => {
                                return (
                                  <option value={item.variant_id} key={key}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <p className="invalid_input">{errors.variant}</p>
                          </FloatingLabel>
                          <FloatingLabel className="mb-3" label="Color">
                            <Form.Select
                              required
                              name="color"
                              value={formData.color}
                              validaterule={["required"]}
                              validatemsg={["Color is required"]}
                              onChange={handleOnChange}
                            >
                              <option value="">Select color</option>
                              {selectedVariant?.colors?.map((item, key) => {
                                return (
                                  <option value={item.color_id} key={key}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <p className="invalid_input">{errors.color}</p>
                          </FloatingLabel>
                        </div>
                      </div>
                      <Button
                        variant="default"
                        className="btn_primary"
                        type="submit"
                      >
                        Next
                      </Button>
                    </div>
                  </Form>
                )}

                {currentStep === 2 && (
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();

                      if (isValidForm(step2Errors)) {
                        setCurrentStep(currentStep + 1);
                      } else {
                        toast.error("Enter valid details");
                      }
                    }}
                  >
                    <div className="pre_order_form">
                      <div className="pof_scrol mb-3">
                        <div className="pod_form_item">
                          <div className="pod_form_legend">Dealer Details</div>

                          <FloatingLabel
                            className="mb-3"
                            label="Select Your country"
                          >
                            <Form.Select
                              required
                              name="country"
                              value={formData.country}
                              onChange={(e) => {
                                handleOnChange(e, 2);
                              }}
                              validaterule={["required"]}
                              validatemsg={["Country is required"]}
                            >
                              <option value=""></option>

                              {allCountries.map((item, key) => {
                                return (
                                  <option key={key} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <p className="invalid_input">
                              {step2Errors.country}
                            </p>
                          </FloatingLabel>

                          <FloatingLabel
                            className="mb-3"
                            label="Select your state"
                          >
                            <Form.Select
                              required
                              name="state"
                              value={formData.state}
                              onChange={(e) => {
                                handleOnChange(e, 2);
                              }}
                              validaterule={["required"]}
                              validatemsg={["State is required"]}
                            >
                              <option value=""></option>

                              {allStates.map((item, key) => {
                                return (
                                  <option key={key} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <p className="invalid_input">{step2Errors.state}</p>
                          </FloatingLabel>

                          <FloatingLabel
                            className="mb-3"
                            label="Select Your city"
                          >
                            <Form.Select
                              required
                              name="city"
                              value={formData.city}
                              onChange={(e) => {
                                handleOnChange(e, 2);
                              }}
                              validaterule={["required"]}
                              validatemsg={["City is required"]}
                            >
                              <option value=""></option>
                              {allCityes.map((item, key) => {
                                return (
                                  <option key={key} value={item}>
                                    {item}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <p className="invalid_input">{step2Errors.city}</p>
                          </FloatingLabel>

                          <FloatingLabel
                            className="mb-3"
                            label="Select your dealer"
                          >
                            <Form.Select
                              required
                              name="dealer"
                              value={formData.dealer}
                              onChange={(e) => {
                                handleOnChange(e, 2);
                              }}
                              validaterule={["required"]}
                              validatemsg={["Dealer is required"]}
                            >
                              <option value=""></option>
                              {allDealers.map((item, key) => {
                                return (
                                  <option key={key} value={item.user_id}>
                                    {item.first_name + " " + item.last_name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            <p className="invalid_input">
                              {step2Errors.dealer}
                            </p>
                          </FloatingLabel>
                        </div>
                        <div className="pod_form_item">
                          <div className="pod_form_legend">Billing Address</div>
                          <FloatingLabel className="mb-3" label="Your Address">
                            <Form.Control
                              type="text"
                              placeholder="Your Address"
                              required
                              name="address"
                              value={formData.address}
                              onChange={(e) => {
                                handleOnChange(e, 2);
                              }}
                              validaterule={["required"]}
                              validatemsg={["Address is required"]}
                            />
                            <p className="invalid_input">
                              {step2Errors.address}
                            </p>
                          </FloatingLabel>
                          {/* 
                          <FloatingLabel
                            className="mb-3"
                            label="Your Address 2"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Your Address 2"
                            />
                          </FloatingLabel>
                          <FloatingLabel
                            className="mb-3"
                            label="Select your state"
                          >
                            <Form.Select>
                              <option value="1"></option>
                            </Form.Select>
                          </FloatingLabel>
                          <FloatingLabel
                            className="mb-3"
                            label="Select your city"
                          >
                            <Form.Select>
                              <option value="1"></option>
                            </Form.Select>
                          </FloatingLabel> */}
                          <FloatingLabel className="mb-3" label="Pincode">
                            <Form.Control
                              type="text"
                              placeholder="Pincode"
                              required
                              name="zip_code"
                              value={formData.zip_code}
                              onChange={(e) => {
                                handleOnChange(e, 2);
                              }}
                              validaterule={["required", "isDecimal"]}
                              validatemsg={[
                                "Pincode is required",
                                "Enter valid pin code",
                              ]}
                            />
                            <p className="invalid_input">
                              {step2Errors.zip_code}
                            </p>
                          </FloatingLabel>
                        </div>
                      </div>
                      <Stack direction="horizontal" gap={2}>
                        <Button
                          variant="default"
                          className="btn_outline outline_dark"
                          onClick={handlePreviousStep}
                        >
                          Back
                        </Button>
                        <Button
                          variant="default"
                          className="btn_primary"
                          type="submit"
                        >
                          Next
                        </Button>
                      </Stack>
                    </div>
                  </Form>
                )}

                {currentStep === 3 && (
                  <Form onSubmit={handleOnSubmit}>
                    <div className="pre_order_form">
                      <div className="pof_scrol mb-3">
                        <div className="pod_form_item">
                          <div className="pod_form_legend">Payment Options</div>
                          <div>
                            <Form.Check
                              inline
                              label="Credit Card"
                              type="radio"
                              name="card_type"
                              value="credit"
                              required
                              onChange={(e) => {
                                handleOnChange(e, 3);
                              }}
                              checked={formData.card_type === "credit"}
                            />
                            <Form.Check
                              inline
                              label="Debit Card"
                              type="radio"
                              name="card_type"
                              value="debit"
                              required
                              onChange={(e) => {
                                handleOnChange(e, 3);
                              }}
                              checked={formData.card_type === "debit"}
                            />
                          </div>
                          <FloatingLabel className="mb-3" label="Card Number">
                            <Form.Control
                              type="text"
                              placeholder="XXXX XXXX XXXX XXXX"
                              required
                              name="card_no"
                              value={formData.card_no}
                              onChange={(e) => {
                                handleOnChange(e, 3);
                              }}
                              validaterule={["required", "isValidCard"]}
                              validatemsg={["Card number is required"]}
                            />
                            <p className="invalid_input">
                              {step3Errors.card_no}
                            </p>
                          </FloatingLabel>

                          <Row className="mx-0">
                            <Col sm={6} className="ps-0">
                              <FloatingLabel
                                className="mb-3"
                                label="Expiry Date"
                              >
                                <Form.Control
                                  required
                                  type="text"
                                  name="card_expiry"
                                  placeholder="mm/yy"
                                  value={expriy_format(formData.card_expiry)}
                                  onChange={(e) => {
                                    handleOnChange(e, 3);
                                  }}
                                />
                                <p className="invalid_input">
                                  {step3Errors.card_expiry}
                                </p>
                              </FloatingLabel>
                            </Col>
                            <Col sm={6} className="pe-0">
                              <FloatingLabel className="mb-3" label="CVV">
                                <Form.Control
                                  type="password"
                                  placeholder="CVV"
                                  name="cvv"
                                  value={formData.cvv}
                                  onChange={(e) => {
                                    handleOnChange(e, 3);
                                  }}
                                  validaterule={["required", "isValidCVV"]}
                                  validatemsg={["CVV is required"]}
                                  autoComplete="false"
                                />
                                <p className="invalid_input">
                                  {step3Errors.cvv}
                                </p>
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <FloatingLabel
                            className="mb-3"
                            label="Cardholder Name"
                          >
                            <Form.Control
                              type="text"
                              placeholder="eg.john doe"
                              required
                              name="card_holder"
                              value={formData.card_holder}
                              validaterule={["required", "isName"]}
                              validatemsg={["Name is required"]}
                              onChange={(e) => {
                                handleOnChange(e, 3);
                              }}
                            />
                            <p className="invalid_input">
                              {step3Errors.card_holder}
                            </p>
                          </FloatingLabel>
                          <div className="checkboax_area d-flex">
                            <Form.Check
                              required
                              inline
                              name="group1"
                              type="checkbox"
                            />
                            <label>
                              Disclaimer:  I agree that by clicking the ‘Submit’
                              button below, I am explicitly agreeing to
                              following:
                              <ul>
                                <li>
                                  1 . Soliciting a call and message via WhatsApp
                                  or any other medium from Endurance Motors or
                                  its affiliates, authorized dealerships,
                                  partners, subsidiary companies etc. on Mobile
                                  number stated by me.
                                </li>

                                <li>
                                  2 . Cancellation charges (Of Rs 500) will be
                                  applicable for booking done from the date of
                                  Launch (after official price reveal) of the
                                  vehicle.
                                </li>
                                <li>
                                  3 . In case of cancellation within period of 6
                                  months from date of booking, amount will be
                                  refunded online into source account only. For
                                  any cancellation beyond this period, refund
                                  would be settled offline between customer and
                                  dealer selected at time of booking.
                                </li>
                              </ul>
                              *Prices/Schemes prevailing at the time of
                              invoice/bill shall be applicable.
                            </label>
                          </div>
                        </div>
                      </div>
                      <Stack direction="horizontal" gap={2}>
                        <Button
                          variant="default"
                          className="btn_outline outline_dark"
                          onClick={handlePreviousStep}
                        >
                          Back
                        </Button>
                        <Button
                          variant="default"
                          className="btn_primary"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Stack>
                    </div>
                  </Form>
                )}
              </>
            </Col>
            <Col md={6}>
              <div className="pre_order_car">
                <img
                  src={BUCKET_DOMAIN + machineDetails?.primary_picture}
                  className="img-fluid"
                  alt={machineDetails.name}
                />
                <Stack direction="horizontal" gap={2} className="mt-4">
                  <div className="pod_title">
                    <h2>{machineDetails.name}</h2>
                    <div className="pod_price">From $20,000</div>
                  </div>
                  <div className="pod_act ml-auto">
                    <Link
                      to={`/details/${slug}`}
                      className="btn btn_outline outline_dark"
                    >
                      Know More
                    </Link>
                  </div>
                </Stack>
              </div>
            </Col>
          </Row>
        </Fade>
      </Container>
    </div>
  );
}
export default PreOrder;
