import React, { useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Container, Row, Col, Button } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faEnvelope,
  faAddressCard,
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

import "./Page.css";

import {
  servicing_and_support,
  warranty_coverage,
  vehicle_insurance,
  maintenance,
} from "../assets/images";
import axios from "../config/axios.config";
import { toast } from "react-toastify";
import { ERROR } from "../helpers/MessageHelper";
import Loader from "../common/loader/Loader";
import validation from "../helpers/validation";
import { useDispatch, useSelector } from "react-redux";
import { STATUS } from "../helpers/Helper";

const SUPPORT_DATA = [
  "Routine Maintenance",
  "Advanced Services",
  "Diagnostic Services",
  "Brake Services",
  "Tire Services",
  "Safety Inspections",
  "Specialty Services",
  "Exterior and Interior Services",
  "Seasonal Services",
  "Warranty and Recall Services",
  "Accessories and Customization",
  "Emergency Services",
];

function Support() {
  const sectionRef = useRef(null);
  const dispatch = useDispatch();

  const { features, status, success, message } = useSelector(
    (state) => state.features
  );
  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    car_id: "",
    type: "",
    name: "",
    email: "",
    phone: "",
    message: "",
    from: "Endurance Motors",
  });

  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState([]);
  const [allFeaturedCars, setAllFeaturedCars] = useState([]);

  useEffect(() => {
    if (status === STATUS.SUCCEEDED) {
      setLoading(false);
      if (success) {
        setAllFeaturedCars(features);
      } else {
        toast.error(message);
      }
    } else if (status === STATUS.FAILED) {
      setLoading(false);
      toast.error(message);
    }
  }, [status, success, message, features, dispatch]);

  useEffect(() => {
    getPageContent();
  }, []);

  const getPageContent = () => {
    setLoading(true);

    axios
      .post("/page-info", { name: "Support", from: "Endurance Motors" })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setPageContent(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const handleOnChange = (e) => {
    e.persist();
    let { name, value } = e.target;
    formData[name] = value;
    setFormData({ ...formData });
    const valid_obj = {
      value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };
    validation(valid_obj).then((err) => {
      errors[name] = err;
      setErrors({ ...errors });
    });
  };

  const isValidForm = (errors) => {
    for (const [, value] of Object.entries(errors)) {
      if (value.length > 0) {
        return false;
      }
    }
    return true;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (isValidForm(errors)) {
      setLoading(true);
      axios
        .post("/service-request", formData)
        .then((res) => {
          setLoading(false);
          if (res.data.status) {
            toast.success(res.data.message);
            setFormData({
              car_id: "",
              type: "",
              name: "",
              email: "",
              phone: "",
              message: "",
            });
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          toast.error(ERROR);
        });
    } else {
      toast.error("Enter valid details");
    }
  };

  return (
    <>
      {loading && <Loader />}
      {pageContent.length > 0 && (
        <Fade duration="2000" triggerOnce="true">
          <div className="page_banner">
            <img src={servicing_and_support} className="img-fluid" alt="" />

            <div className="pb_caption">
              <Container>
                <Row>
                  <Col md={5}>
                    <h2 className="pb_title">{pageContent[0].title}</h2>
                    <div
                      className="pb_text"
                      dangerouslySetInnerHTML={{
                        __html: pageContent[0].description,
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
            <span onClick={scrollToSection} className="scroll_bottom">
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </div>
        </Fade>
      )}
      <section className="section_alt" ref={sectionRef}>
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <Row className="align-items-center">
              <Col md={6} className="alt_img">
                <img src={maintenance} className="img-fluid" alt="" />
              </Col>
              <Col md={6} className="alt_text">
                <div className="heading">
                  <h2 className="title title_lg">Maintenance</h2>
                </div>
                <p>
                  The New Age Baleno is a sight to behold. Carrying forward the
                  Liquid Flow Aesthetic, it exudes boldness from every curve.
                  Its dynamic stance with strong shoulders and sharp character
                  lines flow with harmony, from front to back. So, no matter if
                  you see it coming, or passing by, you won’t be able to look
                  away.The New Age Baleno is a sight to behold. Carrying forward
                  the Liquid Flow Aesthetic, it exudes boldness from every
                  curve. Its dynamic stance with strong shoulders .
                </p>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
      <section className="section_alt">
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <Row className="align-items-center">
              <Col md={{ span: 6, order: 2 }} className="alt_img">
                <img src={warranty_coverage} className="img-fluid" alt="" />
              </Col>
              <Col md={{ span: 6, order: 1 }} className="alt_text">
                <div className="heading">
                  <h2 className="title title_lg">Warranty Coverage</h2>
                </div>
                <p>
                  The New Age Baleno is a sight to behold. Carrying forward the
                  Liquid Flow Aesthetic, it exudes boldness from every curve.
                  Its dynamic stance with strong shoulders and sharp character
                  lines flow with harmony, from front to back. So, no matter if
                  you see it coming, or passing by, you won’t be able to look
                  away.The New Age Baleno is a sight to behold. Carrying forward
                  the Liquid Flow Aesthetic, it exudes boldness from every
                  curve. Its dynamic stance with strong shoulders .
                </p>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
      <section className="section_alt">
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <Row className="align-items-center">
              <Col md={6} className="alt_img">
                <img src={vehicle_insurance} className="img-fluid" alt="" />
              </Col>
              <Col md={6} className="alt_text">
                <div className="heading">
                  <h2 className="title title_lg">Vehicle Insurance</h2>
                </div>
                <p>
                  The New Age Baleno is a sight to behold. Carrying forward the
                  Liquid Flow Aesthetic, it exudes boldness from every curve.
                  Its dynamic stance with strong shoulders and sharp character
                  lines flow with harmony, from front to back. So, no matter if
                  you see it coming, or passing by, you won’t be able to look
                  away.The New Age Baleno is a sight to behold. Carrying forward
                  the Liquid Flow Aesthetic, it exudes boldness from every
                  curve. Its dynamic stance with strong shoulders .
                </p>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
      <section className="reach_us">
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <div className="ru_card">
              <Row>
                <Col md={6}>
                  <div className="cni_card">
                    <div className="heading">
                      <h2 className="title">Reach Us </h2>
                    </div>
                    <div className="cni_item d-flex">
                      <div className="cni_icon">
                        <FontAwesomeIcon icon={faLocationDot} />
                      </div>
                      <div className="cni_body">
                        <div className="cni_label">Address</div>
                        <div className="cni_body">
                          1234 Elm Street, Anytown, CA 90210, USA
                        </div>
                      </div>
                    </div>
                    <div className="cni_item d-flex">
                      <div className="cni_icon">
                        <FontAwesomeIcon icon={faAddressCard} />
                      </div>
                      <div className="cni_body">
                        <div className="cni_label">Postal Code</div>
                        <div className="cni_body">241009</div>
                      </div>
                    </div>
                    <div className="cni_item d-flex">
                      <div className="cni_icon">
                        <FontAwesomeIcon icon={faPhone} />
                      </div>
                      <div className="cni_body">
                        <div className="cni_label">Hotline</div>
                        <div className="cni_body">
                          Support Manager{" "}
                          <a href="mailto:13456564656">13456564656</a>
                        </div>
                      </div>
                    </div>
                    <div className="cni_item d-flex">
                      <div className="cni_icon">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>
                      <div className="cni_body">
                        <div className="cni_label">E-mail</div>
                        <div className="cni_body">
                          <a href="mailto:support@endurancemotors.com">
                            support@endurancemotors.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <Form onSubmit={handleOnSubmit}>
                    <FloatingLabel
                      className="mb-3"
                      label={
                        <>
                          Select Your Car <span className="required">*</span>
                        </>
                      }
                    >
                      <Form.Select
                        required
                        name="car_id"
                        value={formData.car_id}
                        onChange={handleOnChange}
                      >
                        <option value=""></option>

                        {allFeaturedCars.map((item, key) => {
                          return (
                            <option key={key} value={item.car_id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </FloatingLabel>

                    <FloatingLabel
                      className="mb-3"
                      label={
                        <>
                          Select Your Inquiry{" "}
                          <span className="required">*</span>
                        </>
                      }
                    >
                      <Form.Select
                        required
                        name="type"
                        value={formData.type}
                        onChange={handleOnChange}
                      >
                        <option value=""></option>
                        {SUPPORT_DATA.map((item, key) => {
                          return (
                            <option value={item} key={key}>
                              {item}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </FloatingLabel>

                    <FloatingLabel
                      className="mb-3"
                      label={
                        <span>
                          Name <span className="required">*</span>
                        </span>
                      }
                    >
                      <Form.Control
                        type="text"
                        placeholder="eg.john doe"
                        required
                        name="name"
                        value={formData.name}
                        validaterule={["required", "isName"]}
                        validatemsg={["Name is required", "Enter valid name"]}
                        onChange={handleOnChange}
                      />
                      <p className="invalid_input">{errors.name}</p>
                    </FloatingLabel>

                    <FloatingLabel
                      className="mb-3"
                      label={
                        <span>
                          Email <span className="required">*</span>
                        </span>
                      }
                    >
                      <Form.Control
                        type="email"
                        placeholder="eg.john.doe@email.com"
                        required
                        name="email"
                        value={formData.email}
                        validaterule={["required", "isEmail"]}
                        validatemsg={["email id is required"]}
                        onChange={handleOnChange}
                      />
                      <p className="invalid_input">{errors.email}</p>
                    </FloatingLabel>

                    <FloatingLabel
                      className="mb-3"
                      label={
                        <span>
                          Phone <span className="required">*</span>
                        </span>
                      }
                    >
                      <Form.Control
                        type="tel"
                        placeholder="Phone"
                        name="phone"
                        required
                        value={formData.phone}
                        validaterule={["notRequired", "isMobile"]}
                        onChange={handleOnChange}
                      />
                      <p className="invalid_input">{errors.phone}</p>
                    </FloatingLabel>

                    <FloatingLabel
                      className="mb-3"
                      label={
                        <span>
                          Message <span className="required">*</span>
                        </span>
                      }
                    >
                      <Form.Control
                        required
                        style={{ height: "100px" }}
                        as="textarea"
                        placeholder="Message"
                        name="message"
                        value={formData.message}
                        validaterule={["required"]}
                        validatemsg={["Message is required"]}
                        onChange={handleOnChange}
                      />
                      <p className="invalid_input">{errors.message}</p>
                    </FloatingLabel>
                    <Button
                      type="submit"
                      variant="default"
                      className="btn_primary"
                    >
                      Submit
                    </Button>
                  </Form>
                </Col>
              </Row>
            </div>
          </Container>
        </Fade>
      </section>
    </>
  );
}

export default Support;
