import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Link, useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import "./TestDrive.css";
import axios from "../../config/axios.config";
import { ERROR } from "../../helpers/MessageHelper";
import { toast } from "react-toastify";
import Loader from "../../common/loader/Loader";
import { BUCKET_DOMAIN } from "../../helpers/Helper";
import validation from "../../helpers/validation";

function TestDrive() {
  const { slug } = useParams();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [machineDetails, setMachineDetails] = useState({});
  const [allLocations, setAllLocations] = useState([]);

  const [formData, setFormData] = useState({
    model: "",
    car_id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    location_id: "",
  });

  useEffect(() => {
    getMachineDetails();
    getLocations();
    // eslint-disable-next-line
  }, [slug]);

  const getMachineDetails = () => {
    setLoading(true);
    axios
      .get(`/car/${slug}`)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setMachineDetails(res.data.data);
          formData.model = res.data.data.name;
          formData.car_id = res.data.data.car_id;
          setFormData({ ...formData });
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const getLocations = () => {
    setLoading(true);
    axios
      .get("/locations")
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setAllLocations(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const handleOnChange = (e) => {
    e.persist();
    let { name, value } = e.target;

    formData[name] = value;

    setFormData({ ...formData });

    const valid_obj = {
      value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };

    validation(valid_obj).then((err) => {
      errors[name] = err;
      setErrors({ ...errors });
    });
  };

  const isValidForm = (errors) => {
    for (const [, value] of Object.entries(errors)) {
      if (value.length > 0) {
        return false;
      }
    }
    return true;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (isValidForm(errors)) {
      setLoading(true);
      axios
        .post("/schedule-test-drive", formData)
        .then((res) => {
          setLoading(false);
          if (res.data.status) {
            toast.success(res.data.message);
            window.history.back();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          toast.error(ERROR);
        });
    } else {
      toast.error("Enter valid details");
    }
  };

  return (
    <div className="pod_area">
      {loading && <Loader />}

      <Container>
        <Row>
          <Col md={6}>
            <Fade duration="2000" triggerOnce="true">
              <div className="pof_header">
                <div className="heading">
                  <h2 className="title title_lg">Schedule a Test Drive</h2>
                  <div className="sub_title">
                    Explore the Endurance Motors vehicle line-up with its
                    dynamic yet elegant design and cutting-edge technology.
                  </div>
                </div>
              </div>
            </Fade>
          </Col>
        </Row>
        <Fade duration="2000" triggerOnce="true">
          <Row>
            <Col md={6}>
              <Form onSubmit={handleOnSubmit}>
                <div className="pre_order_form">
                  <div className="pod_form_item mb-3">
                    <FloatingLabel
                      className="mb-3"
                      label={
                        <span>
                          Model<span>*</span>
                        </span>
                      }
                    >
                      <Form.Control
                        name="model"
                        value={formData.model}
                        readOnly
                      />
                    </FloatingLabel>
                    <Row className="mx-0">
                      <Col sm={6} className="ps-0">
                        <FloatingLabel
                          className="mb-3"
                          label={
                            <span>
                              First Name<span>*</span>
                            </span>
                          }
                        >
                          <Form.Control
                            type="text"
                            placeholder="First Name"
                            required
                            name="first_name"
                            value={formData.first_name}
                            validaterule={["required", "isName"]}
                            validatemsg={[
                              "First Name is required",
                              "Enter valid first name",
                            ]}
                            onChange={handleOnChange}
                          />
                          <p className="invalid_input">{errors.first_name}</p>
                        </FloatingLabel>
                      </Col>
                      <Col sm={6} className="pe-0">
                        <FloatingLabel
                          className="mb-3"
                          label={
                            <span>
                              Last Name<span>*</span>
                            </span>
                          }
                        >
                          <Form.Control
                            type="text"
                            placeholder="Last Name"
                            required
                            name="last_name"
                            value={formData.last_name}
                            validaterule={["required", "isName"]}
                            validatemsg={[
                              "Last Name is required",
                              "Enter valid last name",
                            ]}
                            onChange={handleOnChange}
                          />
                          <p className="invalid_input">{errors.last_name}</p>
                        </FloatingLabel>
                      </Col>
                      <Col sm={6} className="ps-0">
                        <FloatingLabel
                          className="mb-3"
                          label={
                            <span>
                              Email<span>*</span>
                            </span>
                          }
                        >
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            required
                            name="email"
                            value={formData.email}
                            validaterule={["required", "isEmail"]}
                            validatemsg={["Email id is required"]}
                            onChange={handleOnChange}
                          />
                          <p className="invalid_input">{errors.email}</p>
                        </FloatingLabel>
                      </Col>
                      <Col sm={6} className="pe-0">
                        <FloatingLabel
                          className="mb-3"
                          label={
                            <span>
                              Phone<span>*</span>
                            </span>
                          }
                        >
                          <Form.Control
                            type="tel"
                            placeholder="Phone"
                            required
                            name="phone"
                            value={formData.phone}
                            validaterule={["required", "isMobile"]}
                            validatemsg={["Phone number is required"]}
                            onChange={handleOnChange}
                          />
                          <p className="invalid_input">{errors.phone}</p>
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <FloatingLabel
                      className="mb-3"
                      label={
                        <span>
                          Location<span>*</span>
                        </span>
                      }
                    >
                      <Form.Select
                        placeholder="Location"
                        required
                        name="location_id"
                        value={formData.location_id}
                        validaterule={["required"]}
                        validatemsg={["Location is required"]}
                        onChange={handleOnChange}
                      >
                        <option value="">Select Location</option>
                        {allLocations.map((item, key) => {
                          return (
                            <option value={item.location_id} key={key}>
                              {item.city +
                                ", " +
                                item.state +
                                ", " +
                                item.address}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <p className="invalid_input">{errors.location_id}</p>
                    </FloatingLabel>
                    {/* <div className="set_location mb-4">
                      <Button variant="default" className="sl_button">
                        <FontAwesomeIcon icon={faLocationCrosshairs} /> Set your
                        current Location
                      </Button>
                    </div> */}

                    <div className="checkboax_area d-flex">
                      <Form.Check
                        inline
                        required
                        name="group1"
                        type="checkbox"
                        label="*Consent. By submitting this form you consent to the use of Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
                      />
                    </div>
                  </div>
                  <Button
                    type="submit"
                    variant="default"
                    className="btn_primary"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Col>

            <Col md={6}>
              <div className="pre_order_car">
                <img
                  src={BUCKET_DOMAIN + machineDetails?.primary_picture}
                  className="img-fluid"
                  alt={machineDetails.name}
                />
                <Stack direction="horizontal" gap={2} className="mt-4">
                  <div className="pod_title">
                    <h2>{machineDetails.name}</h2>
                    <div className="pod_price">From $20,000</div>
                  </div>
                  <div className="pod_act ml-auto">
                    <Link
                      to={`/details/${slug}`}
                      className="btn btn_outline outline_dark"
                    >
                      Know More
                    </Link>
                  </div>
                </Stack>
              </div>
            </Col>
          </Row>
        </Fade>
      </Container>
    </div>
  );
}

export default TestDrive;
