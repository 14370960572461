import React, { useRef } from "react";
import { Fade } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "./Page.css";
import { banner_three, servicing_and_support, g5, g8 } from "../assets/images";

function Design() {
  const sectionRef = useRef(null);
  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Fade duration="2000" triggerOnce="true">
        <div className="page_banner">
          <img src={banner_three} className="img-fluid" alt="" />

          <div className="pb_caption">
            <Container>
              <Row>
                <Col md={5}>
                  <h2 className="pb_title">Design</h2>
                  <div className="pb_text">
                    Explore the Endurance Motors vehicle line-up with its
                    dynamic yet elegant design and cutting-edge technology.
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <span onClick={scrollToSection} className="scroll_bottom">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
      </Fade>

      <section className="section_alt" ref={sectionRef}>
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <Row className="align-items-center">
              <Col md={6} className="alt_img">
                <img src={servicing_and_support} className="img-fluid" alt="" />
              </Col>
              <Col md={6} className="alt_text">
                <div className="heading">
                  <h2 className="title title_lg">
                    Innovative Design Philosophy
                  </h2>
                </div>
                <p>
                  At Endurance, we believe that exceptional car design is the
                  fusion of aesthetics, functionality, and advanced engineering.
                  Our design philosophy focuses on creating vehicles that are
                  not only visually striking but also enhance the driving
                  experience through ergonomic interiors, intuitive controls,
                  and cutting-edge technology. Every curve and contour of our
                  cars is meticulously crafted to achieve a harmonious balance
                  between form and function, ensuring that our vehicles stand
                  out on the road while providing unparalleled comfort and
                  convenience.
                </p>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
      <section className="section_alt">
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <Row className="align-items-center">
              <Col md={{ span: 6, order: 2 }} className="alt_img">
                <img src={g5} className="img-fluid" alt="" />
              </Col>
              <Col md={{ span: 6, order: 1 }} className="alt_text">
                <div className="heading">
                  <h2 className="title title_lg">Advanced Aerodynamics</h2>
                </div>
                <p>
                  Aerodynamics plays a crucial role in our car design process,
                  influencing both the performance and efficiency of our
                  vehicles. Our design team employs state-of-the-art
                  computational fluid dynamics (CFD) and wind tunnel testing to
                  optimize the airflow around the car, reducing drag and
                  improving fuel efficiency. This commitment to aerodynamic
                  excellence not only enhances the vehicle's performance but
                  also contributes to a quieter, smoother ride, offering drivers
                  and passengers a superior driving experience.
                </p>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
      <section className="section_alt" style={{ marginBottom: "4rem" }}>
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <Row className="align-items-center">
              <Col md={6} className="alt_img">
                <img src={g8} className="img-fluid" alt="" />
              </Col>
              <Col md={6} className="alt_text">
                <div className="heading">
                  <h2 className="title title_lg">
                    Sustainable and Innovative Materials
                  </h2>
                </div>
                <p>
                  In our pursuit of creating the perfect car, we prioritize the
                  use of sustainable and innovative materials. From lightweight
                  aluminum and high-strength steel to eco-friendly fabrics and
                  recycled components, we carefully select materials that reduce
                  environmental impact without compromising on quality or
                  durability. Our dedication to sustainability extends to every
                  aspect of car design, as we continuously explore new ways to
                  integrate green practices and materials into our production
                  processes, ensuring that our vehicles are as kind to the
                  planet as they are to their owners.
                </p>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
    </>
  );
}

export default Design;
