import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Stack } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pannellum } from "pannellum-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./CarDetails.css";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import {
  IconVariants,
  IconDoorOpen,
  IconFeatures,
  IconInterior,
} from "./index";
import {
  tiggo_pro_specifications_bg,
  configuration,
  interior,
  g1,
  g2,
  g3,
  g4,
  g5,
  g6,
  g7,
  g8,
} from "./../../assets/images";
import { ERROR } from "../../helpers/MessageHelper";
import axios from "../../config/axios.config";
import { toast } from "react-toastify";
import Loader from "../../common/loader/Loader";
import { BUCKET_DOMAIN } from "../../helpers/Helper";

function CarDetails() {
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [machineDetails, setMachineDetails] = useState({});
  const [allVarients, setAllVarients] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeItem, setActiveItem] = useState("Exterior");
  const [selectedColor, setSelectedColor] = useState("");

  const sectionRef = useRef(null);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const swiperThumbRes = {
    0: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 6,
    },
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  useEffect(() => {
    window.CI360.init();
  }, []);

  useEffect(() => {
    getMachineDetails();
    // eslint-disable-next-line
  }, [slug]);

  const getMachineDetails = () => {
    setLoading(true);
    axios
      .get(`/car/${slug}`)
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setMachineDetails(res.data.data);
          // getAllVarient(res.data.data.car_id);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const getAllVarient = (car_id) => {
    setLoading(true);
    axios
      .post("/car-variants", { car_id })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          let data = res.data.data;
          setAllVarients(data);
          if (data.length > 0) {
            data = data[0];
            get360Images(car_id, data.variant_id, data.colors[0]?.color_id);
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const get360Images = (car_id, variant_id, color_id, type = "Exterior") => {
    if (selectedColor === color_id && activeItem === type) return;
    setLoading(true);
    axios
      .post("/360-images", { car_id, variant_id, color_id, type })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setSelectedColor(color_id);
          let { info, path } = res.data.data;
          if (Object.keys(info).length > 0) {
            window.update360View(
              path + "/",
              info.no_of_images,
              info.file_extension
            );
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const GALLERY_IMAGE = [g1, g2, g3, g4, g5, g6, g7, g8];

  return (
    <>
      {loading && <Loader />}

      <Fade duration="2000" triggerOnce="true">
        <div className="page_banner">
          <img
            src={BUCKET_DOMAIN + machineDetails.primary_picture}
            className="img-fluid"
            alt="Primary"
          />
          <div className="pb_caption">
            <Container>
              <h2 className="pb_title">{machineDetails.name}</h2>
              <div className="sp_price">From $20,000</div>
              <Stack direction="horizontal" gap={3}>
                <Link to={`/pre-order/${slug}`} className="btn btn_primary">
                  Pre-Order
                </Link>
                <Link
                  to={`/schedule-a-test-drive/${slug}`}
                  className="btn btn_outline outline_light"
                >
                  Schedule A Test Drive
                </Link>
              </Stack>
            </Container>
          </div>
          <span onClick={scrollToSection} className="scroll_bottom">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
      </Fade>

      <section
        className="specification"
        ref={sectionRef}
        style={{
          background: `url(${
            // BUCKET_DOMAIN + machineDetails.about_image
            g6
          }) center 0% / cover no-repeat`,
        }}
      >
        <Fade duration="2000" triggerOnce="true">
          <div className="sp_specification">
            <Container>
              <Row>
                <Col md={5}></Col>
              </Row>
              <div className="spc_body">
                <div className="model_desc">
                  <Row className="justify-content-center">
                    <Col md={7}>
                      <div className="spc_head">
                        <div className="heading">
                          <h2 className="title title_lg">
                            About {machineDetails.name}
                          </h2>
                          <p>{machineDetails.description}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="model_info mx-0 row">
                    {machineDetails?.specifications?.map((item, key) => {
                      return (
                        <div key={key} className="model_info_item col-five-one">
                          <div className="model_info_title">{item.value}</div>
                          <div className="model_info_legend">{item.label}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </Fade>
      </section>

      {/* <Fade duration="2000" triggerOnce="true">
        <div className="build_area">
          <div
            className={
              activeItem === "Exterior" ||
              activeItem === "Door Open" ||
              activeItem === "Features"
                ? "threesixty_view active"
                : "threesixty_view"
            }
          >
            <div
              className="cloudimage-360"
              id="cloudimage_360"
              data-folder={`../red/`}
              data-filename-x="{index}.webp"
              data-amount-x={24}
              data-speed="200"
              data-drag-speed="80"
              data-autoplay="false"
              data-spin-reverse="true"
              data-bottom-circle="true"
            />
          </div>

          <div
            className={
              activeItem === "interior" ? "pano_view active" : "pano_view"
            }
          >
            {activeItem === "interior" && (
              <Pannellum
                width="100%"
                height="500px"
                image={interior}
                pitch={10}
                yaw={180}
                hfov={110}
                maxHfov={150}
                autoLoad={true}
                doubleClickZoom={false}
                mouseZoom={false}
                showZoomCtrl={false}
              />
            )}
          </div>

          <div className="car_options">
            <div
              className={
                activeItem === "Exterior" ? "co_item active" : "co_item"
              }
              onClick={() => {
                if (activeItem !== "Exterior") {
                  setActiveItem("Exterior");
                  get360Images(
                    machineDetails.car_id,
                    allVarients[0]?.variant_id,
                    allVarients[0]?.colors[0]?.color_id
                  );
                }
              }}
            >
              <span className="co_icon">
                <IconVariants width={60} />
              </span>
              <div className="co_lagend">Exterior</div>
            </div>
            <div
              className={
                activeItem === "Door Open" ? "co_item active" : "co_item"
              }
              onClick={() => {
                if (activeItem !== "Door Open") {
                  setActiveItem("Door Open");
                  get360Images(
                    machineDetails.car_id,
                    allVarients[0]?.variant_id,
                    allVarients[0]?.colors[0]?.color_id,
                    "Door Open"
                  );
                }
              }}
            >
              <span className="co_icon">
                <IconDoorOpen width={40} />
              </span>
              <div className="co_lagend">Door Open</div>
            </div>
            <div
              className={
                activeItem === "Features" ? "co_item active" : "co_item"
              }
              onClick={() => {
                if (activeItem !== "Features") {
                  setActiveItem("Features");
                  get360Images(
                    machineDetails.car_id,
                    allVarients[0]?.variant_id,
                    allVarients[0]?.colors[0]?.color_id,
                    "Features"
                  );
                }
              }}
            >
              <span className="co_icon">
                <IconFeatures width={40} />
              </span>
              <div className="co_lagend">Features</div>
            </div>
            <div
              className={
                activeItem === "interior" ? "co_item active" : "co_item"
              }
              onClick={() => handleItemClick("interior")}
            >
              <span className="co_icon">
                <IconInterior width={40} />
              </span>
              <div className="co_lagend">Interior</div>
            </div>
          </div>
          <div className="car_colors">
            {allVarients[0]?.colors?.map((item, key) => {
              return (
                <span
                  key={key}
                  className="color_item"
                  style={{
                    background: item.code,
                  }}
                  onClick={() => {
                    get360Images(
                      machineDetails.car_id,
                      allVarients[0]?.variant_id,
                      item.color_id
                    );
                  }}
                />
              );
            })}
          </div>
        </div>
      </Fade> */}

      <Fade duration="2000" triggerOnce="true">
        <div className="configuration">
          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <div className="config_img">
                  <img
                    // src={BUCKET_DOMAIN + machineDetails.configuration_image}
                    src={configuration}
                    className="img-fluid"
                    alt="Configuration"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="config_body">
                  <div className="heading">
                    <h2 className="title title_lg">Configuration</h2>
                  </div>
                  <p>{machineDetails.configuration}</p>
                  {/* <Link to="/explore" className="btn btn_outline outline_dark">
                    Explore Now
                  </Link> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Fade>

      <Fade duration="2000" triggerOnce="true">
        <div className="sp_gallery">
          <Container>
            <div className="heading text-center">
              <h2 className="title title_lg">Gallery</h2>
            </div>
            <Swiper
              loop={true}
              slidesPerView={1.4}
              centeredSlides={true}
              navigation={true}
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="gallerySwiper"
            >
              {GALLERY_IMAGE.map((item, key) => {
                return (
                  <SwiperSlide key={key}>
                    <img src={item} alt={key} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <Swiper
              breakpoints={swiperThumbRes}
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={10}
              slidesPerView={6}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="thumbsSwiper"
            >
              {GALLERY_IMAGE.map((item, key) => {
                return (
                  <SwiperSlide key={key}>
                    <img src={item} alt={key} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Container>
        </div>
      </Fade>

      {/* <Fade duration="2000" triggerOnce="true">
        <div>
          <img
            // src={BUCKET_DOMAIN + machineDetails.footer_image}
            src={footer_image}
            className="img-fluid"
            alt="footer"
          />
        </div>
      </Fade> */}
    </>
  );
}

export default CarDetails;
