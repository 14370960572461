import React, { useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "./News.css";
import moment from "moment";
import { news_banner } from "../../assets/images";
import axios from "../../config/axios.config";
import { toast } from "react-toastify";
import { ERROR } from "../../helpers/MessageHelper";
import Loader from "../../common/loader/Loader";
import { BUCKET_DOMAIN, DATE_FORMAT } from "../../helpers/Helper";

function News() {
  const sectionRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [allNews, setAllNews] = useState([]);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    getAllNews();
  }, []);

  const getAllNews = () => {
    setLoading(true);

    axios
      .post("/blog-posts", { for: "Endurance Motors" })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setAllNews(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  return (
    <>
      {loading && <Loader />}

      <Fade duration="2000" triggerOnce="true">
        <div className="page_banner">
          <img src={news_banner} className="img-fluid" alt="" />

          <div className="pb_caption">
            <Container>
              <Row>
                <Col md={5}>
                  <h2 className="pb_title">News & Stories</h2>
                  <div className="pb_text">
                    Explore the Endurance Motors vehicle line-up with its
                    dynamic yet elegant design and cutting-edge technology.
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <span onClick={scrollToSection} className="scroll_bottom">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
      </Fade>
      <section className="news" ref={sectionRef}>
        <Container>
          <Row>
            {allNews.map((item, key) => {
              return (
                <Col key={key} md={6}>
                  <Fade duration="2000" triggerOnce="true">
                    <div className="news_card">
                      <img
                        src={BUCKET_DOMAIN + item.featured_image}
                        className="img-fluid mb-3"
                        alt=""
                      />
                      <div className="sub_title text-uppercase">
                        <small>
                          {moment(item.createdAt).format(DATE_FORMAT.NEWS)}
                        </small>
                      </div>
                      <h4 className="news_title mb-3">{item.title}</h4>
                      <Link
                        to={`/news-and-stories/${item.slug}`}
                        className="btn btn_outline outline_dark"
                      >
                        Know More
                      </Link>
                    </div>
                  </Fade>
                </Col>
              );
            })}

            {/* <Col md={6}>
              <Fade duration="2000" triggerOnce="true">
                <div className="news_card">
                  <img src={news_two} className="img-fluid mb-3" alt="" />
                  <div className="sub_title text-uppercase">
                    <small>January 28, 2024</small>
                  </div>
                  <h4 className="news_title mb-3">
                    Endurance Achieves Milestone in Green Technology Adoption
                  </h4>
                  <Link
                    to="/news-and-stories/endurance-unveils-groundbreaking-sustainability-initiative"
                    className="btn btn_outline outline_dark"
                  >
                    Know More
                  </Link>
                </div>
              </Fade>
            </Col>
            <Col md={6}>
              <Fade duration="2000" triggerOnce="true">
                <div className="news_card">
                  <img src={news3} className="img-fluid mb-3" alt="" />
                  <div className="sub_title text-uppercase">
                    <small>January 28, 2024</small>
                  </div>
                  <h4 className="news_title mb-3">
                    Endurance Achieves Milestone in Green Technology Adoption
                  </h4>
                  <Link
                    to="/news-and-stories/endurance-unveils-groundbreaking-sustainability-initiative"
                    className="btn btn_outline outline_dark"
                  >
                    Know More
                  </Link>
                </div>
              </Fade>
            </Col>
            <Col md={6}>
              <Fade duration="2000" triggerOnce="true">
                <div className="news_card">
                  <img src={news_two} className="img-fluid mb-3" alt="" />
                  <div className="sub_title text-uppercase">
                    <small>January 28, 2024</small>
                  </div>
                  <h4 className="news_title mb-3">
                    Endurance Achieves Milestone in Green Technology Adoption
                  </h4>
                  <Link
                    to="/news-and-stories/endurance-unveils-groundbreaking-sustainability-initiative"
                    className="btn btn_outline outline_dark"
                  >
                    Know More
                  </Link>
                </div>
              </Fade>
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default News;
