import React from "react";
import "./loader.css";

export default function Loader() {
  return (
    <div className="fullscreen_loader">
      {/* <div className="cube-loader">
        <div className="side front" />
        <div className="side back" />
        <div className="side right" />
        <div className="side left" />
        <div className="side top" />
        <div className="side bottom" />
      </div> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="102"
        height="40"
        viewBox="0 0 102 40"
        fill="none"
      >
        <g className="car-wheel car-wheel--front">
          <path
            d="M92.415 31.25C92.415 35.01 89.316 38.058 85.493 38.058M92.415 31.25C92.415 27.4901 89.316 24.442 85.493 24.442M92.415 31.25H78.571M85.493 38.058C81.6701 38.058 78.571 35.01 78.571 31.25M85.493 38.058V24.442M78.571 31.25C78.571 27.4901 81.6701 24.442 85.493 24.442"
            stroke="#ebff00"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g className="car-wheel car-wheel--rear">
          <path
            d="M55.4331 31.25C55.4331 35.01 52.334 38.058 48.5111 38.058M55.4331 31.25C55.4331 27.4901 52.334 24.442 48.5111 24.442M55.4331 31.25H41.5891M48.5111 38.058C44.6882 38.058 41.5891 35.01 41.5891 31.25M48.5111 38.058V24.442M41.5891 31.25C41.5891 27.4901 44.6882 24.442 48.5111 24.442"
            stroke="#ebff00"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          className="car__body"
          d="M37.8081 31.947C35.1531 31.947 34.4681 29.847 35.1201 27.277C35.1201 27.277 38.8301 7.33304 49.2931 3.37504C54.9271 1.79204 56.0171 1.80504 56.0171 1.80504C58.6301 1.36004 62.8551 1.46804 65.4371 2.04204L73.8181 3.90504C76.4081 4.48104 79.9821 6.51104 81.7981 8.43604L88.1461 15.168L94.3911 17.045C97.4891 17.553 100 20.476 100 23.552V27.758C100 28.0106 99.0774 29.9994 96.5 30.7678M38.5001 16.5H92.5101M75 31.947H59.5"
          stroke="#ebff00"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="car__line car__line--top"
          d="M24.5001 17.5H4.4751"
          stroke="#ebff00"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="car__line car__line--middle"
          d="M22.5001 24.5H2.47559"
          stroke="#ebff00"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="car__line car__line--bottom"
          d="M27.5 10.5H8.5"
          stroke="#ebff00"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
