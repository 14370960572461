import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../config/axios.config";
import { STATUS } from "../../helpers/Helper";

const initialState = {
  features: [],
  status: STATUS.IDLE,
  message: "",
  success: false,
};

// Create async thunk for fetching featured-cars
export const getFeatures = createAsyncThunk("cars/getFeatures", async () => {
  const response = await axios.get("/featured-cars");
  return response.data;
});

const featuredSlice = createSlice({
  name: "features",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFeatures.pending, (state) => {
        state.status = STATUS.LOADING;
      })
      .addCase(getFeatures.fulfilled, (state, action) => {
        state.status = STATUS.SUCCEEDED;
        const { data, status, message } = action.payload;
        state.features = data;
        state.success = status;
        state.message = message;
      })
      .addCase(getFeatures.rejected, (state, action) => {
        state.status = STATUS.FAILED;
        state.message = action.error.message;
      });
  },
});

export default featuredSlice.reducer;
