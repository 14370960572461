import React, { useRef } from "react";
import { Fade } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "./Page.css";
import {
  technology,
  age_tech,
  banner_one,
  footer_image,
} from "../assets/images";

function Technology() {
  const sectionRef = useRef(null);
  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Fade duration="2000" triggerOnce="true">
        <div className="page_banner">
          <img src={technology} className="img-fluid" alt="" />

          <div className="pb_caption">
            <Container>
              <Row>
                <Col md={5}>
                  <h2 className="pb_title">Technology</h2>
                  <div className="pb_text">
                    Explore the Endurance Motors vehicle line-up with its
                    dynamic yet elegant design and cutting-edge technology.
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <span onClick={scrollToSection} className="scroll_bottom">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
      </Fade>

      <section className="section_alt" ref={sectionRef}>
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <Row className="align-items-center">
              <Col md={6} className="alt_img">
                <img src={age_tech} className="img-fluid" alt="" />
              </Col>
              <Col md={6} className="alt_text">
                <div className="heading">
                  <h2 className="title title_lg">
                    Cutting-Edge Infotainment Systems
                  </h2>
                </div>
                <p>
                  At Endurance Motors, our vehicles are equipped with the latest
                  infotainment systems to keep you connected and entertained on
                  the go. Featuring high-resolution touchscreens, intuitive
                  interfaces, and seamless smartphone integration through Apple
                  CarPlay and Android Auto, our infotainment systems offer easy
                  access to navigation, music, and hands-free communication.
                  Voice recognition technology and customizable user profiles
                  further enhance your driving experience by allowing you to
                  control various functions without taking your hands off the
                  wheel.
                </p>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
      <section className="section_alt">
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <Row className="align-items-center">
              <Col md={{ span: 6, order: 2 }} className="alt_img">
                <img src={banner_one} className="img-fluid" alt="" />
              </Col>
              <Col md={{ span: 6, order: 1 }} className="alt_text">
                <div className="heading">
                  <h2 className="title title_lg">
                    Advanced Driver Assistance Systems (ADAS)
                  </h2>
                </div>
                <p>
                  Safety is paramount at Endurance Motors, and our advanced
                  driver assistance systems (ADAS) are designed to provide an
                  extra layer of protection for you and your passengers. Our
                  ADAS suite includes features such as adaptive cruise control,
                  lane departure warning, automatic emergency braking, and
                  blind-spot monitoring. These systems use a combination of
                  sensors, cameras, and radar to monitor the vehicle's
                  surroundings and assist the driver in avoiding potential
                  hazards, making every journey safer and more secure.
                </p>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
      <section className="section_alt" style={{ marginBottom: "4rem" }}>
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <Row className="align-items-center">
              <Col md={6} className="alt_img">
                <img src={footer_image} className="img-fluid" alt="" />
              </Col>
              <Col md={6} className="alt_text">
                <div className="heading">
                  <h2 className="title title_lg">
                    Electric and Hybrid Powertrains
                  </h2>
                </div>
                <p>
                  As part of our commitment to sustainability and innovation,
                  Endurance Motors offers a range of electric and hybrid
                  powertrains that deliver exceptional performance while
                  reducing environmental impact. Our electric vehicles (EVs)
                  boast impressive range and fast charging capabilities, making
                  them ideal for both daily commutes and long-distance travel.
                  Our hybrid models combine efficient gasoline engines with
                  electric motors to provide optimal fuel economy and lower
                  emissions. With our advanced powertrain technology, you can
                  enjoy a dynamic driving experience while contributing to a
                  greener future.
                </p>
              </Col>
            </Row>
          </Container>
        </Fade>
      </section>
    </>
  );
}

export default Technology;
