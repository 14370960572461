import React, { useEffect, useRef, useState } from "react";
import Loader from "../common/loader/Loader";
import { ERROR } from "../helpers/MessageHelper";
import { toast } from "react-toastify";
import axios from "../config/axios.config";
import { Fade } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import { terms_conditions_banner } from "../assets/images";

export default function TermsAndCondition() {
  const sectionRef = useRef(null);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState([]);

  useEffect(() => {
    getPageContent();
  }, []);

  const getPageContent = () => {
    setLoading(true);

    axios
      .post("/page-info", { name: "Terms", from: "Endurance Motors" })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setPageContent(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };
  return (
    <>
      {loading && <Loader />}
      {pageContent.length > 0 && (
        <>
          <Fade duration="2000" triggerOnce="true">
            <div className="page_banner pb_sm">
              <img src={terms_conditions_banner} className="img-fluid" alt="" />
              <div className="pb_caption">
                <Container>
                  <Row>
                    <Col md={5}>
                      <h2 className="pb_title">Terms & Conditions</h2>
                    </Col>
                  </Row>
                </Container>
              </div>
              <span onClick={scrollToSection} className="scroll_bottom">
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
            </div>
          </Fade>
          <section className="pp_section" ref={sectionRef}>
            <Container>
              <div
                className="pp_text"
                dangerouslySetInnerHTML={{
                  __html: pageContent[0].description,
                }}
              />
            </Container>
          </section>
        </>
      )}
    </>
  );
}
