import React, { useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { ParallaxBanner } from "react-scroll-parallax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "./Page.css";
import Loader from "../common/loader/Loader";
import axios from "../config/axios.config";
import { ERROR } from "../helpers/MessageHelper";
import { toast } from "react-toastify";
import { BUCKET_DOMAIN } from "../helpers/Helper";

function AboutUs() {
  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState([]);

  const sectionRef = useRef(null);
  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    getPageContent();
  }, []);

  const getPageContent = () => {
    setLoading(true);

    axios
      .post("/page-info", { name: "About Us", from: "Endurance Motors" })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setPageContent(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  return (
    <>
      {loading && <Loader />}

      {pageContent.length > 0 && (
        <>
          <Fade duration="2000" triggerOnce="true">
            <div className="page_banner">
              <img
                src={BUCKET_DOMAIN + pageContent[0]?.image}
                // src={about_us}
                className="img-fluid"
                alt="About Us"
              />

              <div className="pb_caption">
                <Container>
                  <Row>
                    <Col md={5}>
                      <h2 className="pb_title">{pageContent[0]?.title}</h2>
                      <div
                        className="pb_text"
                        dangerouslySetInnerHTML={{
                          __html: pageContent[0]?.description,
                        }}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
              <span onClick={scrollToSection} className="scroll_bottom">
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
            </div>
          </Fade>

          <section className="section_alt" ref={sectionRef}>
            <Fade duration="2000" triggerOnce="true">
              <Container>
                <Row className="align-items-center">
                  <Col md={6} className="alt_img">
                    <img
                      src={BUCKET_DOMAIN + pageContent[1]?.image}
                      className="img-fluid"
                      alt=""
                    />
                  </Col>
                  <Col md={6} className="alt_text">
                    <div className="heading">
                      <h2 className="title title_lg">
                        {pageContent[1]?.title}
                      </h2>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: pageContent[1]?.description,
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Fade>
          </section>
          <section style={{ paddingBottom: "4rem" }} className="section_alt">
            <Fade duration="2000" triggerOnce="true">
              <Container>
                <Row className="align-items-center">
                  <Col md={{ span: 6, order: 2 }} className="alt_img">
                    <img
                      src={BUCKET_DOMAIN + pageContent[2]?.image}
                      className="img-fluid"
                      alt=""
                    />
                  </Col>
                  <Col md={{ span: 6, order: 1 }} className="alt_text">
                    <div className="heading">
                      <h2 className="title title_lg">
                        {pageContent[2]?.title}
                      </h2>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: pageContent[2]?.description,
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Fade>
          </section>

          <ParallaxBanner
            layers={[
              { image: BUCKET_DOMAIN + pageContent[3]?.image, speed: 20 },
              // { image: tiggo_pro_specifications_bg, speed: 20 },
            ]}
            className="sec_cap_bottom"
          >
            <section className="sc_wrap position-relative">
              <Container>
                <Row className="justify-content-center">
                  <Col md={8} className="text-center sc_text text-white">
                    <div className="header">
                      <h2 className="title title_lg">
                        {pageContent[3]?.title}
                      </h2>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: pageContent[3].description,
                      }}
                    />
                    {/* <Link to="#" className="btn btn_outline outline_light">
                      Know More
                    </Link> */}
                  </Col>
                </Row>
              </Container>
            </section>
          </ParallaxBanner>
        </>
      )}
    </>
  );
}

export default AboutUs;
