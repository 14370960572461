import React, { useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Container, Row, Col, Button } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "./Page.css";
import axios from "../config/axios.config";
import validation from "../helpers/validation";
import { ERROR } from "../helpers/MessageHelper";
import { toast } from "react-toastify";
import Loader from "../common/loader/Loader";
import { BUCKET_DOMAIN } from "../helpers/Helper";

const INQUIRY = [
  "Vehicle Availability",
  " Pricing and Financing",
  "Test Drive Requests",
  "Service and Maintenance",
  "Warranty Information",
  "Technical Specifications",
  "Delivery Options",
  "General Inquiries",
];

function Contact() {
  const sectionRef = useRef(null);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    inquiry: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
    from: "Endurance Motors",
  });
  const [pageContent, setPageContent] = useState([]);

  useEffect(() => {
    getPageContent();
  }, []);

  const getPageContent = () => {
    setLoading(true);

    axios
      .post("/page-info", { name: "Contact Us", from: "Endurance Motors" })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setPageContent(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };

  const handleOnChange = (e) => {
    e.persist();
    let { name, value } = e.target;
    formData[name] = value;
    setFormData({ ...formData });
    const valid_obj = {
      value,
      rules: e.target.getAttribute("validaterule"),
      message: e.target.getAttribute("validatemsg"),
    };
    validation(valid_obj).then((err) => {
      errors[name] = err;
      setErrors({ ...errors });
    });
  };

  const isValidForm = (errors) => {
    for (const [, value] of Object.entries(errors)) {
      if (value.length > 0) {
        return false;
      }
    }
    return true;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (isValidForm(errors)) {
      setLoading(true);
      axios
        .post("/contact-request", formData)
        .then((res) => {
          setLoading(false);
          if (res.data.status) {
            toast.success(res.data.message);
            setFormData({
              inquiry: "",
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              message: "",
            });
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          toast.error(ERROR);
        });
    } else {
      toast.error("Enter valid details");
    }
  };

  return (
    <>
      {loading && <Loader />}

      {pageContent.length > 0 && (
        <>
          <Fade duration="2000" triggerOnce="true">
            <div className="page_banner">
              <img
                src={BUCKET_DOMAIN + pageContent[0].image}
                className="img-fluid"
                alt="Contact Us"
              />
              <div className="pb_caption">
                <Container>
                  <Row>
                    <Col md={5}>
                      <h2 className="pb_title">{pageContent[0].title}</h2>
                      <div
                        className="pb_text"
                        dangerouslySetInnerHTML={{
                          __html: pageContent[0].description,
                        }}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
              <span onClick={scrollToSection} className="scroll_bottom">
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
            </div>
          </Fade>
          <section className="contact_area" ref={sectionRef}>
            <Fade duration="2000" triggerOnce="true">
              <Container>
                <Form onSubmit={handleOnSubmit}>
                  <Row className="align-items-center">
                    <Col md={12}>
                      <FloatingLabel
                        className="mb-3"
                        label={
                          <span>
                            Select your Inquiry{" "}
                            <span className="required">*</span>
                          </span>
                        }
                      >
                        <Form.Select
                          required
                          name="inquiry"
                          value={formData.inquiry}
                          onChange={handleOnChange}
                        >
                          <option value=""></option>
                          {INQUIRY.map((item, key) => {
                            return (
                              <option value={item} key={key}>
                                {item}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </FloatingLabel>
                    </Col>
                    <Col md={6}>
                      <FloatingLabel
                        className="mb-3"
                        label={
                          <span>
                            First Name <span className="required">*</span>
                          </span>
                        }
                      >
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          required
                          name="first_name"
                          value={formData.first_name}
                          validaterule={["required", "isName"]}
                          validatemsg={[
                            "First Name is required",
                            "Enter valid first name",
                          ]}
                          onChange={handleOnChange}
                        />
                        <p className="invalid_input">{errors.first_name}</p>
                      </FloatingLabel>
                    </Col>
                    <Col md={6}>
                      <FloatingLabel
                        className="mb-3"
                        label={
                          <span>
                            Last Name <span className="required">*</span>
                          </span>
                        }
                      >
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          required
                          name="last_name"
                          value={formData.last_name}
                          validaterule={["required", "isName"]}
                          validatemsg={[
                            "Last Name is required",
                            "Enter valid last name",
                          ]}
                          onChange={handleOnChange}
                        />
                        <p className="invalid_input">{errors.last_name}</p>
                      </FloatingLabel>
                    </Col>
                    <Col md={6}>
                      <FloatingLabel
                        className="mb-3"
                        label={
                          <span>
                            Email <span className="required">*</span>
                          </span>
                        }
                      >
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          required
                          name="email"
                          value={formData.email}
                          validaterule={["required", "isEmail"]}
                          validatemsg={["email id is required"]}
                          onChange={handleOnChange}
                        />
                        <p className="invalid_input">{errors.email}</p>
                      </FloatingLabel>
                    </Col>
                    <Col md={6}>
                      <FloatingLabel className="mb-3" label="Phone">
                        <Form.Control
                          type="tel"
                          placeholder="Phone"
                          name="phone"
                          value={formData.phone}
                          validaterule={["notRequired", "isMobile"]}
                          onChange={handleOnChange}
                        />
                        <p className="invalid_input">{errors.phone}</p>
                      </FloatingLabel>
                    </Col>
                    <Col md={12}>
                      <FloatingLabel
                        className="mb-3"
                        label={
                          <span>
                            Message <span className="required">*</span>
                          </span>
                        }
                      >
                        <Form.Control
                          required
                          style={{ height: "100px" }}
                          as="textarea"
                          placeholder="Message"
                          name="message"
                          value={formData.message}
                          validaterule={["required"]}
                          validatemsg={["Message is required"]}
                          onChange={handleOnChange}
                        />
                        <p className="invalid_input">{errors.message}</p>
                      </FloatingLabel>
                    </Col>
                    <Col md={9}>
                      <div className="checkboax_area d-flex">
                        <Form.Check
                          inline
                          required
                          name="group1"
                          type="checkbox"
                          feedback="You must check this field"
                          feedbackType="invalid"
                          label={
                            <span>
                              <strong>*Consent.</strong> By providing your
                              contact information and submitting this form, you
                              consent to Endurance Motors collecting, storing,
                              and processing your personal data in accordance
                              with our Privacy Policy. This information will be
                              used to respond to your inquiries, provide
                              customer support, and send you updates and
                              promotional materials related to our products and
                              services.
                            </span>
                          }
                        />
                      </div>
                    </Col>
                    <Col md={3} className="contact_cta">
                      <Button
                        type="submit"
                        variant="default"
                        className="btn_primary"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Fade>
          </section>
        </>
      )}
    </>
  );
}

export default Contact;
