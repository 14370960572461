import React, { useEffect, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { dealers_banner } from "../assets/images";
import { toast } from "react-toastify";
import { ERROR } from "../helpers/MessageHelper";
import Loader from "../common/loader/Loader";
import axios from "../config/axios.config";

function DealerLocation() {
  const sectionRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [allDealerLocation, setAllDealerLocation] = useState([]);

  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    getAllDealerlocation();
  }, []);

  const getAllDealerlocation = () => {
    setLoading(true);

    axios
      .get("/locations")
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setAllDealerLocation(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        toast.error(ERROR);
      });
  };
  return (
    <>
      {loading && <Loader />}

      <Fade duration="2000" triggerOnce="true">
        <div className="page_banner">
          <img src={dealers_banner} className="img-fluid" alt="" />

          <div className="pb_caption">
            <Container>
              <Row>
                <Col md={5}>
                  <h2 className="pb_title">Dealer Locations</h2>
                  <div className="pb_text">
                    Kindly, call your dealer and book an appointment before
                    visiting.
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <span onClick={scrollToSection} className="scroll_bottom">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
      </Fade>
      <section className="section loc_area" ref={sectionRef}>
        <Fade duration="2000" triggerOnce="true">
          <Container>
            <Row>
              {allDealerLocation.map((item, key) => {
                return (
                  <Col key={key} md={3} sm={6}>
                    <div className="loc_card d-flex">
                      <div className="loc_header">
                        <span className="loc_icon">
                          <FontAwesomeIcon icon={faLocationDot} />
                        </span>
                      </div>
                      <div className="loc_body">
                        <h5 className="loc_title">{item.city}</h5>
                        <div className="loc">
                          {item.address +
                            ", " +
                            item.state +
                            ", " +
                            item.zip_code}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}

              {/* <Col md={3} sm={6}>
                <div className="loc_card d-flex">
                  <div className="loc_header">
                    <span className="loc_icon">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </span>
                  </div>
                  <div className="loc_body">
                    <h5 className="loc_title">Classicwheels</h5>
                    <div className="loc">
                      838 Broadway, New York, NY 10003, USA
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={3} sm={6}>
                <div className="loc_card d-flex">
                  <div className="loc_header">
                    <span className="loc_icon">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </span>
                  </div>
                  <div className="loc_body">
                    <h5 className="loc_title">Sleekauto</h5>
                    <div className="loc">
                      20 Cooper Square, New York, NY 10003, USA
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={3} sm={6}>
                <div className="loc_card d-flex">
                  <div className="loc_header">
                    <span className="loc_icon">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </span>
                  </div>
                  <div className="loc_body">
                    <h5 className="loc_title">Auto Mart</h5>
                    <div className="loc">
                      20 Cooper Square, New York, NY 10003, USA
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={3} sm={6}>
                <div className="loc_card d-flex">
                  <div className="loc_header">
                    <span className="loc_icon">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </span>
                  </div>
                  <div className="loc_body">
                    <h5 className="loc_title">Motorbay</h5>
                    <div className="loc">
                      20 Cooper Square, New York, NY 10003, USA
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={3} sm={6}>
                <div className="loc_card d-flex">
                  <div className="loc_header">
                    <span className="loc_icon">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </span>
                  </div>
                  <div className="loc_body">
                    <h5 className="loc_title">Carmatcher</h5>
                    <div className="loc">
                      20 Cooper Square, New York, NY 10003, USA
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={3} sm={6}>
                <div className="loc_card d-flex">
                  <div className="loc_header">
                    <span className="loc_icon">
                      <FontAwesomeIcon icon={faLocationDot} />
                    </span>
                  </div>
                  <div className="loc_body">
                    <h5 className="loc_title">Speedy Wheels</h5>
                    <div className="loc">
                      20 Cooper Square, New York, NY 10003, USA
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </Fade>
      </section>
    </>
  );
}

export default DealerLocation;
